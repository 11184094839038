import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { omit } from '@core/helpers';
import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { IFilters, IImportJobResult, ILine } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-lines-list',
  templateUrl: './lines-list.component.html',
  styles: []
})
export class LinesListComponent extends GenericListComponent {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  @Input() public isNDInactive = false;
  @Input() public hasSecondaryOperatorLine = false;
  @Input() public hasSecondaryLine = false;
  @Input() public hasDataLine = false;
  @Input() public isEditing = false;
  @Output() public createSecondaryLineFunction = new EventEmitter();

  public loadingItemAction: boolean;
  public disableTaskExport = false;
  public disableExportImport = false;

  public readonly usageOptions: Record<string, string> = {
    voice: 'Voix',
    data: 'Data',
    data_premium: 'Data premium',
    temporary: 'Dépannage',
    secondary: 'Secondaire',
  };

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService,
    public signalsService: SignalsService
  ) {
    super(injector);
    this.localFilters = {
      ...this.localFilters,
      is_active: true
    };
    this.localDisabledButtons = {
      create: true,
      createSecondary: true,
      import: false,
      export: false
    };
    this.localDisabledColumns = {
      id: true,
      is_up: true,
      quarantine: true,
      server_vrf__proxy_client: true,
      external_label: true,
      network_device__operator_line__collection_node__equipments_backbone: true,
      network_device__operator_line__collection_node__code: true,
      network_device__entity__parent__name_or_code: true,
      interface_l3: true
    };
  }

  public createSecondary(): void {
    if (this.createSecondaryLineFunction.observers.length > 0) {
      this.createSecondaryLineFunction.emit();
      return;
    }
  }

  public confirmDelete(wcmTable: WcmTableComponent, item): void {
    const msgTitle = `Suppression d'une ligne réseau`;
    let msgBody = `Confirmez-vous la suppression de cette ligne réseau ?
      <ul>`;

    const allowedTypes = ['l2_ptp', 'interco'];
    if (allowedTypes.includes(item.type)) {
      msgBody += ` <li>Pensez à supprimer l'interface sur l'équipement <b>${item.network_device.code}</b></li>`;
    }

    const collectionNodeCode = item.network_device?.operator_line?.collection_node?.code;
    if (collectionNodeCode) {
      msgBody += `<li>Pensez à supprimer la sous interface sur l'équipement <b>${collectionNodeCode}</b></li>`;
    }

    msgBody += `</ul>`;

    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._delete(wcmTable, item),
      () => {}
    );
  }

  public showImportLinesModal(wcmTable: WcmTableComponent): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modal.componentInstance.uploadUrl = this.apiProvitool.lines.lines_from_xlsx_import_url();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des lignes réseau WAN';

    modal.result.then(
      () => wcmTable.refreshTable(),
      () => {}
    );
  }

  public downloadTemplate(): void {
    this.apiShiva.templates.detailByName('network_lines_import')
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      })
      .catch(() => this.toastr.error('Erreur lors du téléchargement du template'));
  }

  public export(tableFilters: IFilters): void {
    const needsConfirmation = this.wcmTable.itemCount >= 100;
    if (needsConfirmation) {
      const modalResult = this.wcmModalsService.confirm(
        `Export volumineux`,
        `Vous êtes sur le point d'exporter ${this.wcmTable.itemCount} éléments.<br> Confirmez-vous l'opération ?`,
        `Oui, sachant que ça risque de ralentir le système`,
        `Non, je réduis le nombre de résultats`
      );

      modalResult.then(
        () => this._doExport(tableFilters),
        () => {}
      );
    } else {
      this._doExport(tableFilters);
    }
  }

  private _doExport(tableFilters: IFilters): void {
    const filters = omit(tableFilters, 'offset', 'limit');
    const taskName = `Export des lignes réseaux`;

    this.disableTaskExport = true;
    this.apiProvitool.lines.export(filters)
      .then((res) => {
        // When the server response is received, and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch(() => this.toastr.error(`Echec de la demande d'export. Veuillez réessayer.`))
      .finally(() => this.disableTaskExport = false);
  }

  public exportImportTemplate(items: {[key: number]: ILine}): void {
    this.disableExportImport = true;
    const selectedItems = Object.keys(items);
    if (!selectedItems.length) {
      this.wcmModalsService.alert(
        `Aucun élément sélectionné.`,
        `Afin de procéder à l'export du template d'import, vous devez préalablement sélectionner au moins un élément de la liste.`
      );
      return;
    }
    const payload: IFilters = {
      id__in: Object.keys(items).join(','),
    };
    this.apiProvitool.lines.exportImportTemplate(payload)
      .then((res: IImportJobResult) => {
        this.signalsService.broadcastJobStart('Export des lignes réseaux pour import', res.job_id);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch(() => this.toastr.error(`Echec de la demande d'export. Veuillez réessayer.`))
      .finally(() => this.disableExportImport = false);
  }

  private _delete(wcmTable: WcmTableComponent, item): void {
    this.loadingItemAction = true;
    this.apiProvitool.lines.delete(item.code)
      .then(() => {
        wcmTable.refreshTable();
        this.signalsService.broadcast('model-history-list-refresh');
      })
      .catch(() => this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.'))
      .finally(() => this.loadingItemAction = false);
  }

  public get exportBtnDisabled(): boolean {
    return this.disableExportImport || !this.wcmTable.selectedCount;
  }
}
