<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>

      <div *ngIf="mode === 'normal'">
        <a href="{{salesforceBaseUrl}}/{{detail.salesforce_id}}" target="_blank"
            class="btn btn-link" *ngIf="detail.salesforce_id">
            <i class="fa fa-external-link-alt"></i> Salesforce
        </a>

        <app-entities-detail-quick-actions [entityPk]="pk"
                                          [entity]="detail"
                                          (createWoiShipment)="newWoiShipment()"
                                          (createWoi)="newWoiFromQuickActions($event)"
                                          >
        </app-entities-detail-quick-actions>

        <button type="button" class="btn btn-default" (click)="edit()" style="margin-left: 5px;"
                [disabled]="entityIsBlocked">
          Modifier
        </button>

        <button type="button" class="btn btn-default toggle-btn" (click)="showLogoAndMap = !showLogoAndMap"
                *ngIf="detail.code" data-toggle="tooltip"  data-placement="left"
                [title]="showLogoAndMap ? 'Cacher le bloc logo et carte' : 'Afficher le bloc logo et carte'"
                [ngClass]="{'active': showLogoAndMap}">
          <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.code">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.code">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <div role="alert" class="alert alert-{{paymentDelay?.alertType || 'info'}}" *ngIf="displayPaymentDelayAlert">
    Ce client est en retard de paiement depuis <b>{{paymentDelay.days_late}} jours</b>.
    {{paymentDelay.num_late_invoices}} factures sont en attente de paiement
    <span *ngIf="hasPermissions('ShivaBackend:InvoiceCanDisplayUnpaidAmount')">pour un montant d'impayé de {{paymentDelay.sum_late_invoices | currency:'EUR':'symbol':'1.2'}}</span>.
  </div>

  <app-entity-alerts-alert [entityAlerts]="entityAlerts"> </app-entity-alerts-alert>

  <app-argos-event-mutes-alert [entity]="detail"></app-argos-event-mutes-alert>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="row">
      <div class="col-sm-12 col-md-4 col-md-push-8" *ngIf="showLogoAndMap" style="background-color: #eee; padding: 10px;">
        <h4>Logo et carte</h4>
        <div class="flex center" style="justify-content: space-around;">
          <div [ngStyle]="{'display': hideBrokenLogo ? 'none' : 'initial'}">
            <img src="{{detail.logoUrl}}" style="max-height: 100px"
                (error)="hideBrokenLogo = true"/>
          </div>

          <div *ngIf="detail.id && mode === 'normal' && detail.is_parent">
            <em *ngIf="hideBrokenLogo">Aucun logo disponible.</em>
            <button class="btn btn-default btn-sm" (click)="fileInput.click()">
              <span>Mettre à jour le logo</span>
              <input type="file" id="importLogoInput"
                    ng2FileSelect [uploader]="logoUploader"
                    (onFileSelected)="updateLogo($event)"
                    [hidden]="true" #fileInput
                    [accept]="'image/*'" />
            </button>
          </div>
        </div>
        <div *ngIf="hideBrokenLogo && !detail.is_parent">
          <em>Aucun logo disponible sur l'entité parente.</em>
        </div>

        <div class="row" style="margin-top: 12px;">
          <div class="col-sm-12">
            <em *ngIf="!(detail.location?.latitude && detail.location?.longitude)">
              La carte n'est pas disponible car la latitude et la longitude de l'adresse principale de l'entité ne sont définies.
            </em>
            <div  *ngIf="detail.location?.latitude && detail.location?.longitude">
              <div leaflet style="height: 300px" [leafletOptions]="mapLeafletOptions" [leafletCenter]="mapLeafletCenter">
                <div [leafletLayer]="mapLayer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12" [ngClass]="{'col-md-8 col-md-pull-4': showLogoAndMap}">
        <div class="row" *ngIf="detail.code">
          <div class="col-sm-12">
            <h3 style="margin-top: 0px;" class="flex">
              <span class="flex-left">{{detail.code}} / {{detail.customer_ref}}</span>
              <span class="label label-danger flex-right"
                    *ngIf="detail.id && !detail.is_open && detail.is_active">
                Site fermé <span *ngIf="detail.close_date">le {{detail.close_date|amDateFormat:'L'}}</span>
              </span>
              <span class="label label-danger flex-right"
                    *ngIf="detail.id && !detail.is_active">
                Site désactivé
              </span>
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-2 col-md-4 control-label">
                  Client <i class="required">*</i>
                </label>
                <div class="col-sm-10 col-md-8">
                  <input class="form-control wcm-highlight-req" name="name" [(ngModel)]="detail.name" required="true" [readonly]="mode === 'normal'"/>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 col-md-4 control-label">
                  Ville
                </label>
                <div class="col-sm-10 col-md-8">
                  <p class="form-control-static">
                    {{detail.location?.city}} {{detail.location?.zipcode ? '(' + detail.location?.zipcode + ')' : ''}}
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 col-md-4 control-label">
                  Adresse
                </label>
                <div class="col-sm-10 col-md-8">
                  <p class="form-control-static">
                    <span style="white-space: pre-wrap;">{{detail.location?.address}}</span>
                    <app-copy-to-clipboard [data]="getFormattedAddr()" *ngIf="detail.location">
                    </app-copy-to-clipboard>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 col-md-4 control-label">
                  Pays
                </label>
                <div class="col-sm-10 col-md-8">
                  <p class="form-control-static">{{detail.location?.country | countryName}}</p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 col-md-4 control-label">
                  Groupement
                </label>
                <div class="col-sm-10 col-md-8">
                  <app-entity-field *ngIf="parentList"
                                    [(ngModel)]="detail.parent"
                                    name="parent"
                                    [hideCode]="true"
                                    [filters]="parentList.filters"
                                    [disabledButtons]="parentList.disabledButtons"
                                    [disabled]="mode === 'normal'"
                                    (ngModelChange)="onParentUpdate()">
                  </app-entity-field>
                </div>
              </div>
              <div class="form-group" *ngIf="detail.parent || detail.is_parent">
                <label class="col-sm-2 col-md-4 control-label">
                  Type d'entité
                </label>
                <div class="col-sm-10 col-md-8">
                  <app-entity-available-type-field [disabled]="mode === 'normal'" [(ngModel)]="detail.type" name="type"
                                                  [filters]="{entity__code: detail.parent?.code || detail.code}">
                  </app-entity-available-type-field>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 col-md-4 control-label">
                  Horaires
                </label>
                <div class="col-sm-10 col-md-8" *ngIf="detail.opening_hours">
                  <app-opening-hour-field [openingHours]="detail.opening_hours" [entityTimezone]="detail.timezone" [entityCode]="detail.code"
                                          (refreshOpeningHours)="refreshOpeningHours()">
                  </app-opening-hour-field>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 col-md-4 control-label">
                  Ref. Filiale
                </label>
                <div class="col-sm-10 col-md-8">
                  <app-input-list-field [(ngModel)]="detail.affiliate_ref" name="affiliate_ref"
                                        *ngIf="existingAffiliateRefs" [disabled]="mode === 'normal'"
                                        [list]="existingAffiliateRefs">
                  </app-input-list-field>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label class="col-sm-2 col-md-4 control-label">
                Téléphone
              </label>
              <div class="col-sm-10 col-md-8">
                <p class='form-control-static'>
                  <a href="tel:{{detail.location?.phone}}">{{detail.location?.phone}}</a>
                </p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 col-md-4 control-label">
                Mail
              </label>
              <div class="col-sm-10 col-md-8">
                <p class="form-control-static">{{detail.location?.email}}</p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 col-md-4 control-label">
                Contact
              </label>
              <div class="col-sm-10 col-md-8">
                <app-contact-field disabled="true" [(ngModel)]="detail.default_contact" name="contact"></app-contact-field>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 col-md-4 control-label">
                Référence SAGE
              </label>
              <div class="col-sm-10 col-md-8">
                <p class="form-control-static">{{detail.client_code}}</p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 col-md-4 control-label">
                Référent commercial
              </label>
              <div class="col-sm-10 col-md-8">
                <app-user-field [(ngModel)]="detail.account_owner" name="account_owner"
                                [disabled]="mode === 'normal' || (this.detail?.code && !hasPermissions('ShivaBackend:EntityCanUpdateAccountOwner'))">
                </app-user-field>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 col-md-4 control-label">
                Resp. Opérationnel de Compte
              </label>
              <div class="col-sm-10 col-md-8">
                <app-user-field [(ngModel)]="detail.manager_roc" name="manager_roc"
                                [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateROC')">
                </app-user-field>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 col-md-4 control-label">
                Chargé(e) de recouvrement
              </label>
              <div class="col-sm-10 col-md-8">
                <app-user-field [(ngModel)]="detail.manager_recovery" name="manager_recovery"
                                [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateRecoveryManager')">
                </app-user-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group form-horizontal">
            <label class="col-md-2 control-label truncate-label">
              Commentaire
            </label>
            <div class="col-lg-10 col-md-12">
              <div *ngIf="mode === 'normal'; else mdEditBlock"><markdown [data]="detail.description" style="white-space: pre-wrap"></markdown></div>
              <ng-template #mdEditBlock>
                <angular-markdown-editor textareaId="descriptionEditor" name="description" [(ngModel)]="detail.description" [disabled]="mode === 'normal'"
                    [options]="editorOptions" rows="8"></angular-markdown-editor>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.code">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <!-- TAB 0 : Informations -->
      <li [ngbNavItem]="0">
        <a ngbNavLink>Informations <i class="required" *ngIf="!isInfoFormValid">*</i></a>
        <ng-template ngbNavContent>
          <form class="form-horizontal" name="infoForm" #infoForm="ngForm">

            <div *ngIf="tabsStatus[0]">
              <div class="form-group">
                <label class="col-sm-4 col-md-2 control-label">
                  Réf client (Entité)
                </label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" name="customerRef" [(ngModel)]="detail.customer_ref"
                          [readonly]="mode === 'normal'"/>
                </div>

                <label class="col-sm-4 col-md-2 control-label">
                  Slug
                </label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" [(ngModel)]="detail.slug" name="slug"
                        [pattern]="slugPattern"
                        (ngModelChange)="onSlugUpdated(infoForm.valid)"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanEditSlug')"/>
                  <span class="error-message" *ngIf="infoForm.controls.slug?.errors?.pattern">
                    Caractères invalides utilisés. Veuillez retirer espaces et caractères spéciaux autres que - et _.
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 col-md-2 control-label">
                  Identifiant légal
                </label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" [(ngModel)]="detail.company_identifier" [readonly]="mode === 'normal'"
                          name="companyIdentifier" placeholder="ex. SIRET" />
                </div>

                <label class="col-sm-4 col-md-2 control-label">Internationnal / En création / Public</label>
                <div class="col-sm-8 col-md-4">
                  <div class="checkbox">
                    <p-checkbox [disabled]="mode === 'normal'" [binary]="true"
                                [(ngModel)]="detail.is_company_identifier_required" name="is_company_identifier_required">
                    </p-checkbox>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 col-md-2 control-label">Id Externe</label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" [(ngModel)]="detail.external_id" [readonly]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateExternalId')"
                        name="external_id"/>
                </div>

                <label class="col-sm-4 col-md-2 control-label" data-toggle="tooltip" data-placement="left" title="TVA intracommunautaire">
                  TVA intracom.
                </label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" [(ngModel)]="detail.intracommunity_vat" [readonly]="mode === 'normal'"
                          name="intracommunityVat"/>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 col-md-2 control-label">
                  Code NAF
                </label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" [(ngModel)]="detail.naf_code" name="nafCode" disabled="true"/>
                </div>

                <label class="col-sm-4 col-md-2 control-label">
                  Label NAF
                </label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" [(ngModel)]="detail.naf_label" name="nafLabel" disabled="true"/>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-4 col-md-2 control-label">
                  NDI de référence
                </label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" name="ndi" [(ngModel)]="detail.ndi" [readonly]="mode === 'normal'"/>
                </div>

                <label class="col-sm-4 col-md-2 control-label">
                  Code Immeuble
                </label>
                <div class="col-sm-8 col-md-4">
                  <input class="form-control" name="building_code" [(ngModel)]="detail.building_code"  [readonly]="mode === 'normal'">
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-4 col-md-2 control-label">
                  Créée le
                </label>
                <div class="col-sm-8 col-md-4">
                  <p class="form-control-static">
                    {{detail.created_at | amDateFormat:'L LT'}}
                  </p>
                </div>
                <label class="col-sm-4 col-md-2 control-label">
                  Créée par
                </label>
                <div class="col-sm-8 col-md-4">
                  <p class="form-control-static">
                    <app-wcm-user [username]="detail.created_by"></app-wcm-user>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-4 col-md-2 control-label">
                  Source
                </label>
                <div class="col-sm-8 col-md-4">
                  <p class="form-control-static">
                    <a routerLink="/entity-bulk-import/detail/{{detail.bulk_import.id}}" *ngIf="detail.source === 'bulk'">
                      Création de sites en masse
                    </a>

                    <span *ngIf="detail.source === 'antoine'">
                      Antoine
                    </span>

                    <a href="{{salesforceBaseUrl}}/{{detail.salesforce_id}}" target="_blank" *ngIf="detail.source === 'salesforce'">
                      Salesforce
                    </a>

                    <span *ngIf="detail.source === 'wgp'">
                      WGP
                    </span>
                  </p>
                </div>
              </div>

            </div>
          </form>
        </ng-template>

      </li>

      <!-- TAB 1 : Adresses -->
      <li [ngbNavItem]="1" *ngIf="detail.code && hasPermissions('Antoine:LocationCanDisplay')">
        <a ngbNavLink>Adresses</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-xs-12 col-sm-11 col-sm-offset-1">
              <!-- <md-checkbox modal md-no-ink class="md-primary" [(ngModel)]="detail.use_primary_location"
                           aria-label="usePrimaryLocation">
              </md-checkbox> -->
              <p-checkbox [(ngModel)]="detail.use_primary_location"  name="usePrimaryLocation"  [disabled]="mode === 'normal'"
                        [binary]="true">
              </p-checkbox>
              <label for="usePrimaryLocation">
                Utiliser l'adresse principale comme adresse d'envoi pour la facturation.
              </label>
            </div>
          </div>

          <hr/>

          <app-entities-detail-locations [entity]="detail"  *ngIf="tabsStatus[1]"
                                        (locationSaved)="fetchAfterLocationSave()">
          </app-entities-detail-locations>

        </ng-template>
      </li>

      <!-- TAB 2 : Annuaire de contacts -->
      <li [ngbNavItem]="2" *ngIf="detail.code && !detail.parent && hasPermissions('Antoine:ContactCanDisplay')">
        <a ngbNavLink>Annuaire de contacts</a>
        <ng-template ngbNavContent>
          <app-contacts-list *ngIf="contactsList && tabsStatus[2]"
                    [filters]="contactsList.filters" [disableUrlUpdate]="true"
                    [disabledColumns]="contactsList.disabledColumns"
                    [disabledButtons]="contactsList.disabledButtons">
          </app-contacts-list>
        </ng-template>
      </li>

      <!-- TAB 3 : Contacts -->
      <li [ngbNavItem]="3" *ngIf="detail.code && hasPermissions('Antoine:ContactCanDisplay')">
        <a ngbNavLink>Contacts</a>
        <ng-template ngbNavContent>
          <app-entities-detail-contacts-list [entity]="detail" *ngIf="tabsStatus[3]">
          </app-entities-detail-contacts-list>
        </ng-template>
      </li>

      <!-- TAB 4 : Configuration -->
      <li [ngbNavItem]="4">
        <a ngbNavLink>Configuration  <i class="required" *ngIf="!isConfigFormValid">*</i> </a>
        <ng-template ngbNavContent>
          <form class="form-horizontal" name="configForm" #configForm="ngForm">
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Société liée <i class="required">*</i>
              </label>

              <div class="col-sm-4">
                <app-company-field class="wcm-highlight-req" name="companyAffinity"
                                   [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')"
                                   [(ngModel)]="detail.company_affinity"
                                   (ngModelChange)="onCompanyUpdate(detail.company_affinity, configForm.valid)"
                                   required="true">
                </app-company-field>
              </div>

              <label class="col-sm-2 control-label">
                Référence SAGE
              </label>

              <div class="col-sm-4">
                <input disabled="true" class="form-control" name="clientCode" [(ngModel)]="detail.client_code"  [readonly]="mode === 'normal'"/>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Méthode de paiement
              </label>

              <div class="col-sm-4">
                <app-payment-method-field [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')"
                                          [(ngModel)]="detail.payment_method_affinity" name="paymentMethodAffinity">
                </app-payment-method-field>
              </div>

              <label class="col-sm-2 control-label">
                Modalité de paiement
              </label>

              <div class="col-sm-4">
                <app-payment-term-field [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')"
                                        [(ngModel)]="detail.payment_term_affinity" name="paymentTermAffinity">
                </app-payment-term-field>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Devise
              </label>

              <div class="col-sm-4">
                <app-currency-field [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')"
                                    [(ngModel)]="detail.currency_affinity" name="currencyAffinity">
                </app-currency-field>
              </div>

              <label class="col-sm-2 control-label">
                Régime fiscal spécifique
              </label>
              <div class="col-sm-4">
                <app-fiscal-position-field [disabled]="mode === 'normal' || !detail.company_affinity?.code || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')"
                                           [filters]="{company__code: detail.company_affinity?.code}" name="fiscalPositionAffinity"
                                           [(ngModel)]="detail.fiscal_position_affinity"
                                           class="wcm-highlight-req">
                </app-fiscal-position-field>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Langue de correspondance
              </label>
              <div class="col-sm-4">
                <ng-container *ngIf="detail.parent; else listLanguage">
                  <select [(ngModel)]="detail.language_affinity" class="form-control"
                          name="languageAffinity"
                          [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                    <option [ngValue]="null">Hérité du parent</option>
                    <option [ngValue]="'ENG'">Anglais</option>
                    <option [ngValue]="'ESP'">Espagnol</option>
                    <option [ngValue]="'FRA'">Français</option>
                  </select>
                </ng-container>
                <ng-template #listLanguage>
                  <app-language-field [disableEmptyValue]="true"
                                      [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')"
                                      [(ngModel)]="detail.language_affinity" name="languageAffinity"
                                      *ngIf="!detail.parent">
                  </app-language-field>
                </ng-template>
              </div>

              <label class="col-sm-2 control-label">
                Type de paiement
              </label>
              <div class="col-sm-4">
                <select modal [(ngModel)]="detail.payable_mode" class="form-control"
                        name="payableMode"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [ngValue]="null">{{nullPayableLabel}}</option>
                  <option *ngFor="let state of payableOptions" [ngValue]="state.value">{{state.label}}</option>
                </select>
              </div>
            </div>

            <hr />

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Groupage de la facturation
              </label>

              <div class="col-sm-4">
                <select [(ngModel)]="detail.invoice_grouping" class="form-control"
                        name="invoiceGrouping"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [ngValue]="null" *ngIf="detail.parent">Hérité du parent</option>
                  <option [ngValue]="null" *ngIf="!detail.parent">Standard (par référence client)</option>
                  <option [ngValue]="'by-order'">Par commande</option>
                  <option [ngValue]="'by-reference'">Par référence client</option>
                  <option [ngValue]="'by-customer'">Par entité</option>
                </select>
              </div>

              <label class="col-sm-2 control-label">
                Frais récurrents
              </label>

              <div class="col-sm-4">
                <select modal [(ngModel)]="detail.invoice_recurring" class="form-control"
                        name="invoiceRecurring"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [ngValue]="null" *ngIf="detail.parent">Hérité du parent</option>
                  <option [ngValue]="null" *ngIf="!detail.parent">Standard (groupé avec le reste)</option>
                  <option [ngValue]="'standard'">Groupé avec le reste</option>
                  <option [ngValue]="'apart'">Dans des factures à part</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-2 control-label">
                Groupe de facturation
              </label>
              <div class="col-md-4">
                <app-input-list-field [(ngModel)]="detail.invoice_group_tag" name="invoiceGroupTag"
                                      [list]="existingInvoiceGroupTags" *ngIf="existingInvoiceGroupTags"
                                      [disabled]="mode === 'normal' || !hasPermissions('Alexia:FinanceInvoice', 'ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                </app-input-list-field>
              </div>
              <label class="col-md-2 control-label">
                Marque
              </label>
              <div class="col-md-4">
                <app-input-list-field [(ngModel)]="detail.brand" name="brand"
                                *ngIf="existingBrands" [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanSetBrand')"
                                [list]="existingBrands">
                </app-input-list-field>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-2 control-label">
                Type d'envoi
              </label>
              <div class="col-md-4">
                <select class="form-control"
                        [(ngModel)]="detail.invoice_send_kind" name="invoiceSendKind"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [ngValue]="null">Hérité du parent</option>
                  <option [ngValue]="'grouped'">Envoi groupé</option>
                  <option [ngValue]="'split'">Envoi séparé</option>
                </select>
              </div>

              <label class="col-md-2 control-label">
                Type de groupe
              </label>
              <div class="col-md-4">
                <select class="form-control"
                        [(ngModel)]="detail.type_group" name="typeGroup"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [ngValue]="'Intra-Group'">Intra-Groupe</option>
                  <option [ngValue]="'Extra-Group'">Extra-Groupe</option>
                  <option [ngValue]="'Adista'">Adista</option>
                </select>
              </div>
            </div>

            <hr />

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Recevoir les factures par courrier
              </label>
              <div class="col-sm-4">
                <select [(ngModel)]="detail.invoice_post" class="form-control"
                        (ngModelChange)="onInvoiceTypesUpdate('post')" name="invoicePost"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [ngValue]="null">Hérité du parent</option>
                  <option [ngValue]="true">Oui</option>
                  <option [ngValue]="false">Non</option>
                </select>
              </div>

              <label class="col-sm-2 control-label">
                Inclure XLS dans l'email
              </label>
              <div class="col-sm-4">
                <select [(ngModel)]="detail.invoice_inc_xlsx" class="form-control" name="invoiceIncXlsx"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [value]="null">Hérité du parent</option>
                  <option [value]="true">Oui</option>
                  <option [value]="false">Non</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Détail de la facturation
              </label>
              <div class="col-sm-4">
                <select [(ngModel)]="detail.invoice_detail_type" class="form-control" name="invoiceDetailType"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [ngValue]="null" *ngIf="detail.parent">Hérité du parent</option>
                  <option [ngValue]="null" *ngIf="!detail.parent">Pas de détail</option>
                  <option [ngValue]="'by-order'">Par commande</option>
                  <option [ngValue]="'by-site'">Par site</option>
                </select>
              </div>

              <label class="col-sm-2 control-label">
                Générer un fichier plateforme
              </label>
              <div class="col-sm-4">
                <select [(ngModel)]="detail.invoice_platform" class="form-control"
                        (ngModelChange)="onInvoiceTypesUpdate('platform')" name="invoicePlatform"
                        [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  <option [ngValue]="null">Hérité du parent</option>
                  <option [ngValue]="'esker'">ESKER</option>
                  <option [ngValue]="'freedz'">Freedz</option>
                  <option [ngValue]="'chorus'">Chorus</option>
                  <option [ngValue]="'sy'">SY</option>
                  <option [ngValue]="'no-platform'">Aucun</option>
                </select>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Compresser les factures envoyées
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.send_compressed_invoice"  name="sendCompressedInvoice"
                            [binary]="true" [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')">
                  </p-checkbox>
                </div>
              </div>

              <label class="col-sm-2 control-label">
                Objet du mail de facturation
              </label>
              <div class="col-sm-4">
                <input [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:EntityCanUpdateFinancialConfiguration')" class="form-control" name="invoiceSubject" [(ngModel)]="detail.invoice_subject" />
              </div>
            </div>
          </form>
        </ng-template>
      </li>

      <!-- TAB 5 : Comptes portail -->
      <li [ngbNavItem]="5" *ngIf="detail.code">
        <a ngbNavLink>Comptes portail</a>
        <ng-template ngbNavContent>
          <app-entity-portal-accounts-list [entity]="detail" [disableUrlUpdate]="true"  *ngIf="tabsStatus[5]">
          </app-entity-portal-accounts-list>
        </ng-template>
      </li>

       <!-- TAB 6 : Devis -->
      <li [ngbNavItem]="6" *ngIf="detail.code">
        <a ngbNavLink>Devis</a>
        <ng-template ngbNavContent>
          <app-quotes-list [filters]="quotesList.filters"
                            [disableUrlUpdate]="true" *ngIf="tabsStatus[6]"
                            [disabledColumns]="quotesList.disabledColumns"
                            (createFunction)="quotesList.create()">
          </app-quotes-list>
        </ng-template>
      </li>

      <!-- TAB 7 : Commandes -->
      <li [ngbNavItem]="7" *ngIf="detail.code">
        <a ngbNavLink>Commandes</a>
        <ng-template ngbNavContent>
          <app-orders-list [financeMode]="false" [filters]="ordersList.filters" [disableUrlUpdate]="true"
                            [entity]="detail" *ngIf="tabsStatus[7]"
                            [disabledColumns]="ordersList.disabledColumns">
          </app-orders-list>
        </ng-template>
      </li>

      <!-- TAB 8 : Factures -->
      <li [ngbNavItem]="8" *ngIf="detail.code && hasPermissions('Alexia:FinanceInvoice')">
        <a ngbNavLink>Factures & avoirs</a>
        <ng-template ngbNavContent>
          <app-invoices-list [filters]="invoicesList.filters" [disableUrlUpdate]="true"
                             [disabledColumns]="invoicesList.disabledColumns"
                             [disabledButtons]="invoicesList.disabledButtons"
                             *ngIf="tabsStatus[8]"></app-invoices-list>
        </ng-template>
      </li>

      <!-- TAB 9 : Services -->
      <li [ngbNavItem]="9" *ngIf="detail.code && hasPermissions('Antoine:TraceabilityCanDisplay')">
        <a ngbNavLink>Services</a>
        <ng-template ngbNavContent>
          <app-traceabilities-list [filters]="traceabilitiesList.filters" [disableUrlUpdate]="true"
                                   [disabledColumns]="traceabilitiesList.disabledColumns"
                                   *ngIf="tabsStatus[9]">
          </app-traceabilities-list>
        </ng-template>
      </li>

      <!-- TAB 10 : Types d'entités -->
      <li [ngbNavItem]="10" *ngIf="detail.code && !detail.parent">
        <a ngbNavLink>Types d'entités</a>
        <ng-template ngbNavContent>
          <div class="alert alert-info" *ngIf="!detail.is_parent && !forceAvailableTypes">
            Les types sont désactivés pour cette entité. Si vous souhaitez créer et lui associer d'autres entités (sites, magasins...), vous pouvez activer cette fonctionnalité, puis choisir les types applicables dans la liste des types disponibles.<br>
            <br>
            Il est inutile d'activer cette fonctionnalité si votre entité n'a pas vocation à devenir un groupement.<br>
            <br>
            <button class="btn btn-default" (click)="forceAvailableTypes = true">Activer les types</button>
          </div>
          <app-entity-available-types-list [filters]="entityAvailableTypesList.filters" [entity]="detail" [disableUrlUpdate]="true"
                                           *ngIf="tabsStatus[10] && (detail.is_parent || forceAvailableTypes)">
          </app-entity-available-types-list>
        </ng-template>
      </li>

      <!-- TAB 11 : Sites -->
      <li [ngbNavItem]="11" *ngIf="detail.code">
        <a ngbNavLink>Sites</a>
        <ng-template ngbNavContent>
          <app-entities-list [filters]="entitiesList.filters" disableUrlUpdate="true"
                          *ngIf="tabsStatus[11]"
                         (createFunction)="entitiesList.create()"
                         [disabledColumns]="entitiesList.disabledColumns"
                         [disabledButtons]="entitiesList.disabledButtons"
                         controller-out="entitiesListController"></app-entities-list>

        </ng-template>
      </li>

      <!-- TAB 12 : Équipements -->
      <li [ngbNavItem]="12" *ngIf="detail.code && hasPermissions('Antoine:EquipmentCanDisplay')">
        <a ngbNavLink>Équipements</a>
        <ng-template ngbNavContent>
          <app-equipments-list [filters]="equipmentsList.filters" [entity]="detail" *ngIf="tabsStatus[12]"
                               [disableUrlUpdate]="true"
                               [disabledColumns]="equipmentsList.disabledColumns"
                               [disabledButtons]="equipmentsList.disabledButtons">
          </app-equipments-list>
        </ng-template>
      </li>

      <!-- TAB 13 : Support -->
      <li [ngbNavItem]="13" *ngIf="detail.code">
        <a ngbNavLink>Support</a>
        <ng-template ngbNavContent>
          <app-work-order-items-list [filters]="ticketsWoiList.filters" *ngIf="tabsStatus[13]"
                                     [disableUrlUpdate]="true"
                                     [disabledColumns]="ticketsWoiList.disabledColumns"
                                     [disabledButtons]="ticketsWoiList.disabledButtons"
                                     (createFunction)="newWoi()">
          </app-work-order-items-list>
        </ng-template>
      </li>

      <!-- TAB 14 : Projets (PJI list) -->
      <li [ngbNavItem]="14" *ngIf="detail.code && hasPermissions('Antoine:ProjectCanDisplay')">
        <a ngbNavLink>Projets</a>
        <ng-template ngbNavContent>
          <app-project-items-list [filters]="projectItemsList.filters" *ngIf="tabsStatus[14]"
                                  [disableUrlUpdate]="true"
                                  [disabledColumns]="projectItemsList.disabledColumns">
          </app-project-items-list>
        </ng-template>
      </li>

      <!-- TAB 15 : Demandes Logistiques -->
      <li [ngbNavItem]="15" *ngIf="detail.code && hasPermissions('Antoine:LogisticsRequestCanDisplay')">
        <a ngbNavLink>Demandes Logistiques</a>
        <ng-template ngbNavContent>
          <app-logistics-requests-list [filters]="logisticsRequestsList.filters" *ngIf="tabsStatus[15]"
                                       [disableUrlUpdate]="true"
                                       [disabledColumns]="logisticsRequestsList.disabledColumns"
                                       [disabledButtons]="logisticsRequestsList.disabledButtons">
          </app-logistics-requests-list>
        </ng-template>
      </li>

      <!-- TAB 16 : Expéditions -->
      <li [ngbNavItem]="16" *ngIf="detail.code">
        <a ngbNavLink>Expéditions</a>
        <ng-template ngbNavContent>
          <app-work-order-items-list [filters]="shipmentsWoiList.filters" *ngIf="tabsStatus[16]"
                                     [disableUrlUpdate]="true"
                                     [disabledColumns]="shipmentsWoiList.disabledColumns"
                                     [disabledButtons]="shipmentsWoiList.disabledButtons"
                                     (createFunction)="newWoiShipment()">
          </app-work-order-items-list>
        </ng-template>
      </li>

      <!-- TAB 17 : Conditions de vente -->
      <!-- ?active_tab=17 is used in redirection from price-books-replace-active-modal -->
      <li [ngbNavItem]="17" *ngIf="detail.code && hasPermissions('EvaBackend:ModifyPriceBook')">
        <a ngbNavLink>Conditions de vente</a>
        <ng-template ngbNavContent>
          <app-price-books-list [disableUrlUpdate]="true" *ngIf="tabsStatus[17]" [filters]="priceBooksList.filters"
                                [disabledButtons]="priceBooksList.disabledButtons"></app-price-books-list>
        </ng-template>
      </li>

      <!-- TAB 18 : Liens opérateur -->
      <li [ngbNavItem]="18" *ngIf="detail.code && hasPermissions('Antoine:SdaCanDisplay')">
        <a ngbNavLink>SDAs</a>
        <ng-template ngbNavContent>
          <app-sdas-list [disableUrlUpdate]="true" *ngIf="tabsStatus[18]"
                        [filters]="sdaList.filters"
                        [disabledColumns]="sdaList.disabledColumns" >
          </app-sdas-list>
        </ng-template>
      </li>

      <!-- TAB 19 : Liens opérateur -->
      <li [ngbNavItem]="19" *ngIf="detail.code && hasPermissions('Antoine:OperatorLineCanDisplay')">
        <a ngbNavLink>Liens opérateur</a>
        <ng-template ngbNavContent>
          <app-operator-lines-list [disableUrlUpdate]="true" *ngIf="tabsStatus[19]"
                                   [filters]="operatorLinesList.filters"
                                   [disabledColumns]="operatorLinesList.disabledColumns"
                                   [disabledButtons]="operatorLinesList.disabledButtons" >
          </app-operator-lines-list>
        </ng-template>
      </li>

      <!-- TAB 20 : Provi réseau -->
      <!-- ?active_tab=20 is used in redirection from network-devices-detail -->
      <li [ngbNavItem]="20" *ngIf="detail.code && hasPermissions('Antoine:NetworkCanDisplay')">
        <a ngbNavLink>Provi réseau</a>
        <ng-template ngbNavContent>
          <app-entities-detail-provi-network *ngIf="tabsStatus[20]" [entity]="detail">
          </app-entities-detail-provi-network>
        </ng-template>
      </li>

      <!-- TAB 21 : Maintenances -->
      <li ngbNavItem="21" *ngIf="hasPermissions('Argos:MaintenanceWindowCanCreate')">
        <a ngbNavLink>Maintenances</a>
        <ng-template ngbNavContent>
          <app-argos-event-mutes-list [disableUrlUpdate]="true" *ngIf="tabsStatus[21]"
                                      [filters]="argosEventMutesList.filters"
                                      [disabledColumns]="argosEventMutesList.disabledColumns"
                                      (createFunction)="argosEventMutesList.create()">
          </app-argos-event-mutes-list>
        </ng-template>
      </li>

      <!-- TAB 22 : Alertes -->
      <li [ngbNavItem]="22" *ngIf="detail.code">
        <a ngbNavLink>Alertes</a>
        <ng-template ngbNavContent>
          <app-entities-detail-entity-alerts *ngIf="tabsStatus[22]" [entity]="detail">
          </app-entities-detail-entity-alerts>
        </ng-template>
      </li>

      <!-- TAB 23 : Rapports -->
      <li [ngbNavItem]="23" *ngIf="detail.code && hasPermissions('ShivaBackend:EntityCanDisplayReport')">
        <a ngbNavLink>Rapports</a>
        <ng-template ngbNavContent>
          <app-entities-detail-reports *ngIf="tabsStatus[23]" [entity]="detail">
          </app-entities-detail-reports>
        </ng-template>
      </li>

      <!-- TAB 24 : Historique -->
      <li [ngbNavItem]="24" *ngIf="detail.code">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[24]" [id]="detail.id" model="entity" [isParent]="!detail.parent">
          </app-model-histories-list>
        </ng-template>
      </li>

      <!-- TAB 25 : Convention -->
      <li [ngbNavItem]="25" *ngIf="detail.code && detail.is_parent">
        <a ngbNavLink>Conventions</a>
        <ng-template ngbNavContent>
          <label class="col-sm-2 control-label">
            Label monitoring
          </label>
          <hr/>
          <form class="form-horizontal" name="conventionForm" #conventionForm="ngForm">
            <div class="form-group" *ngIf="tabsStatus[25]">
              <label class="col-sm-2 control-label">
                Ligne réseau
              </label>
              <div class="col-sm-4">
                <app-naming-convention-field [object]="'external_label'"
                                             name="externalLabelNamingConvention" [(ngModel)]="externalLabelNamingConvention"
                                             (ngModelChange)="onChangeNamingConvention($event, 'external_label_naming_convention')">
                </app-naming-convention-field>
              </div>

              <label class="col-sm-2 control-label">
                Firewall
              </label>
              <div class="col-sm-4">
                <app-naming-convention-field [object]="'firewall_label'"
                                             name="firewallLabelNamingConvention" [(ngModel)]="firewallLabelNamingConvention"
                                             (ngModelChange)="onChangeNamingConvention($event, 'firewall_label_naming_convention')">
                </app-naming-convention-field>
              </div>
            </div>
            <div class="form-group" *ngIf="tabsStatus[25]">
              <label class="col-sm-2 control-label">
                Switch
              </label>
              <div class="col-sm-4">
                <app-naming-convention-field [object]="'switch_label'"
                                             name="switchLabelNamingConvention" [(ngModel)]="switchLabelNamingConvention"
                                             (ngModelChange)="onChangeNamingConvention($event, 'switch_label_naming_convention')">
                </app-naming-convention-field>
              </div>
            </div>
          </form>
        </ng-template>
      </li>

      <!-- TAB 26 : ArgosEvents -->
      <li [ngbNavItem]="26">
        <a ngbNavLink>Événements Argos</a>
        <ng-template ngbNavContent>
          <app-argos-events-list
              [filters]="argosEventsList.filters"
              [disableUrlUpdate]="true"
              *ngIf="tabsStatus[26]">
          </app-argos-events-list>
        </ng-template>
     </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav" style="padding-bottom: 24px;"></div>
  </ng-container> <!-- /tabs container -->
</div>
