<app-wcm-table #wcmTable [api]="api" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="entities-list-refresh" urlUpdateSignal="entities-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="entities"
               [allowPreferences]="allowPreferences" [enableRemoveTableFilters]="enableRemoveTableFilters">
  <ng-template appWcmHeaderTitle text="Entités" targetRouterLink="/entities/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <div class="btn-group" role="group" *ngIf="!localDisabledButtons.type">
      <button type="button" class="btn btn-default"
          [ngClass]="{'active': wcmTable.filters.is_prospect === 'true'}"
          (click)="filterProspects(wcmTable)">
        Prospects
      </button>
      <button type="button" class="btn btn-default"
          [ngClass]="{'active': wcmTable.filters.is_customer === 'true'}"
          (click)="filterCustomers(wcmTable)">
        Clients
      </button>
      <button type="button" class="btn btn-default"
          [ngClass]="{'active': wcmTable.filters.parent__isnull === 'false'}"
          (click)="filterSites(wcmTable)">
        Sites
      </button>
      <button type="button" class="btn btn-default"
          [ngClass]="{'active': wcmTable.filters.is_prospect !== 'true' && wcmTable.filters.is_customer !== 'true' && !wcmTable.filters.parent__isnull}"
          (click)="filterAll(wcmTable)">
        Tous
      </button>
    </div>

    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions(permissionToUseForCreation)">
      <i class="fas fa-plus"></i> Nouvelle entité
    </button>

    <button class="btn btn-default" (click)="createMerakiParent()" [disabled]="merakiParentLoading"
            *ngIf="!localDisabledButtons.createParent && hasPermissions('Antoine:MerakiCanCreate')">
      <i class="fas fa-plus"></i> Ajouter un parent
    </button>

    <button class="btn btn-default" (click)="removeMerakiParent(wcmTable)" [disabled]="wcmTable.selectedCount < 1 || merakiParentLoading"
            *ngIf="!localDisabledButtons.removeParent && hasPermissions('Antoine:MerakiCanCreate')">
      <i class="fas fa-minus"></i> Retirer un parent
    </button>

    <button class="btn btn-default" (click)="select()"
        *ngIf="!localDisabledButtons.select">
      <i class="fas fa-plus"></i> Sélectionner des entités
    </button>

    <button type="button" class="btn btn-default" (click)="filteredExport(wcmTable.filters, 'entities')"
        *ngIf="!localDisabledButtons.export && hasPermissions('Antoine:ExportAdmin')" [disabled]="disableTaskExport">
      <i class="far fa-file-excel"></i>
      Exporter
    </button>

    <button type="button" class="btn btn-default" (click)="filteredExportPortalAccount(wcmTable.filters, 'entities')"
            *ngIf="!localDisabledButtons.export && hasPermissions('Antoine:ExportPortalAccount')" [disabled]="disableTaskExport">
      <i class="far fa-file-excel"></i>
      Exporter les comptes portails
    </button>

    <div class="btn-group dropdown" *ngIf="canDisplayOpeningHoursButton">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
        <i class="fas fa-file-excel"></i> Horaires sur site ... <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li *ngIf="canDisplayOpeningHoursImportButton" >
          <a role="button" (click)="showImportOpeningHoursModal()">
            Importer des horaires sur site
          </a>
        </li>
        <li *ngIf="canDisplayOpeningHoursImportButton" >
          <a role="button" (click)="downloadTemplate('import_contact_opening_hours')">
            Télécharger le template d'import d'horaires sur site
          </a>
        </li>
        <li *ngIf="canDisplayOpeningHoursExportButton" >
          <a role="button" (click)="filteredExportOpeningHours(wcmTable.filters)">
            Exporter les horaires sur site
          </a>
        </li>
      </ul>
    </div>

    <div class="btn-group dropdown" *ngIf="canDisplayContactsButton">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
        <i class="fas fa-file-excel"></i> Contacts sur site ... <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li *ngIf="canDisplayContactsImportButton" >
          <a role="button" (click)="showImportContactsModal()">
            Importer des contacts sur site
          </a>
        </li>
        <li *ngIf="canDisplayContactsImportButton" >
          <a role="button" (click)="downloadTemplate('import_contact_direct_relations')">
            Télécharger le template d'import de contacts sur site
          </a>
        </li>
        <li *ngIf="canDisplayContactsExportButton" >
          <a role="button" (click)="filteredExport(wcmTable.filters, 'contacts')">
            Exporter les contacts sur site
          </a>
        </li>
      </ul>
    </div>
  </ng-template>

  <app-wcm-col id="selection" field="id" header="Sélection" type="checkbox" class="text-center"
               [preventSort]="true" width="10"></app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/entities/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="customer_ref" header="Réf client (Entité)" class="text-center"></app-wcm-col>

  <app-wcm-col field="affiliate_ref" header="Ref. Filiale" class="text-center" type="none" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="parent__name_or_code" sortField="parent__name" header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.parent?.name}}" link="/entities/detail/{{item.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="parent__code" header="Code parent" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.parent?.code}}" link="/entities/detail/{{item.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="self_or_parent__code" header="Code entité ou code parent" class="text-center"
               [preventSort]="true" width="120" *ngIf="contactSelectionEntityView"></app-wcm-col>

  <app-wcm-col field="is_not_assigned_to_contact_code" header="Non assigné au contact" class="text-center"
               [preventSort]="true" width="120" *ngIf="contactSelectionEntityView"></app-wcm-col>

  <app-wcm-col field="type__entity_type__name" header="Type" class="text-center"></app-wcm-col>

  <app-wcm-col field="name" header="Nom" class="text-center" [focus]="true"></app-wcm-col>

  <app-wcm-col field="company_affinity__name" header="Société liée" class="text-center" width="200"></app-wcm-col>

  <app-wcm-col field="location__city" header="Ville" class="text-center" width="120"></app-wcm-col>

  <app-wcm-col field="location__zipcode" header="Code postal" class="text-center" width="100"></app-wcm-col>

  <app-wcm-col field="location__country" header="Pays" class="text-center" width="150">
      <ng-template appWcmColFilter let-col="col" let-dt="dt" let-filters="filters">
        <app-country-select [(ngModel)]="filters[col.field]" name="country-select"
                            (ngModelChange)="dt.filter(filters[col.field], col.field, null)">
        </app-country-select>
      </ng-template>
      <ng-template appWcmColBody let-item="item">
        {{ item.location?.country | countryName }}
      </ng-template>
  </app-wcm-col>

  <app-wcm-col field="account_owner" sortField="account_owner_filter" header="Réf. Commercial" type="user" class="text-center" width="100"></app-wcm-col>

  <app-wcm-col field="manager_roc" sortField="manager_roc_filter" header="R.O.C." type="user" class="text-center" width="100"></app-wcm-col>

  <app-wcm-col field="manager_recovery" sortField="manager_recovery_filter" header="Chargé(e) de recouvrement" type="user" class="text-center" width="100"></app-wcm-col>

  <app-wcm-col field="salesforce_id" header="Salesforce" class="text-center" type="none" [preventSort]="true" width="1">
    <ng-template appWcmColBody let-item="item">
      <a href="{{salesforceBaseUrl}}/{{item.salesforce_id}}"
          *ngIf="item.salesforce_id" target="_blank">
        <i class="fa fa-external-link-alt wcm-1dot7x"></i>
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="shops" header="Sites" class="text-center" type="none" [preventSort]="true" width="1">
    <ng-template appWcmColBody let-item="item">
      <a href="#/entities/list?parent__name_or_code={{item.code}}" *ngIf="item.is_parent">
        <i class="fa fa-home wcm-1dot7x"></i>
      </a>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="is_open" header="Site fermé" class="text-center" width="80" type="select"
               [additionalArgs]="{options: isClosedOptions}">
  </app-wcm-col>

  <app-wcm-col field="close_date" header="Fermé le" class="text-center" width="150" type="dateRange"></app-wcm-col>

  <app-wcm-col field="is_invoiced" header="Site facturé" class="text-center" width="80" type="yesNo"></app-wcm-col>

  <app-wcm-col field="type__invoiceable" header="Site facturable" class="text-center" width="80" type="yesNo"></app-wcm-col>

  <app-wcm-col *ngIf="!localDisabledColumns.action"
    label="Action" id="action" type="none" class="text-center" [preventSort]="true" width="180">
  <ng-template appWcmColBody let-item="item">
  <div class="action-btn-bar">
    <button class="btn btn-default btn-xs" (click)="remove(item)"
      data-toggle="tooltip" title="Retirer la porte de collecte de l'offre">
      <i class="fa fa-trash-alt"></i>
    </button>
  </div>
  </ng-template>
  </app-wcm-col>

</app-wcm-table>
