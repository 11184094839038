import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';

import { ApiService } from './api.service';
import { ConfigService } from '@core/config/config.service';
import {
  IEquipment,
  IEquipmentLocation,
  IEquipmentModel,
  IExternalEquipmentOrder,
  IExternalEquipmentOrderItem,
  IRandomSlipNumberResponse,
  IFilters,
  IJobResponse,
  IListResult,
  ILogisticsSite,
  ITagBulkPatchResponse,
  ITags,
  ITemplateEmail,
  IWaiting,
  IWaitingConfiguration,
  IWOIIntervention,
  IWorkOrderItems,
  IReceiveEquipmentResponse,
} from '@core/interfaces';
import { AuthService } from '@core/services/auth.service';
import { ICarrierAccountPassword } from '../interfaces/carrier-account-items';


@Injectable({
  providedIn: 'root'
})
export class ApiShivaService {

  constructor(
    private readonly api: ApiService,
    private readonly authService: AuthService,
    private readonly config: ConfigService
  ) {}

  private _call<T = unknown>(method, path, data?, disableError?, responseType?): Promise<T> {
    return this.api.call(method, this.config.apis.shiva + path, data, disableError, responseType);
  }

  public status() {
    return this._call('GET', '/api/status/');
  }

  public get billing_uploads() {
    const baseUrl = '/api/billing-uploads';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      createUrl: () => {
        return this.config.apis.shiva + `${baseUrl}/`;
      }
    };
  }

  public get invoices() {
    const baseUrl = '/api/invoices';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },

      generate: (payload) => {
        return this._call('POST', `${baseUrl}/create-invoices/`, payload);
      },

      create_refund_from_invoice: (id, itemsIds) => {
        return this._call('POST', `${baseUrl}/${id}/create-refund/`, {item_ids: itemsIds});
      },

      download: (id, inline) => {
        const jwt = this.authService.getToken();
        const obj = {
          token: jwt,
          inline
        };

        return this.config.apis.shiva + `${baseUrl}/${id}/download/${this.api.buildQueryString(obj)}`;
      },

      download_xlsx: (id) => {
        const jwt = this.authService.getToken();
        const obj = {
          token: jwt
        };
        return this.config.apis.shiva + `${baseUrl}/${id}/download_xlsx/${this.api.buildQueryString(obj)}`;
      },

      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },

      export_detail: (filters) => {
        return this._call('POST', `${baseUrl}/export-detail/${this.api.buildQueryString(filters)}`, null, true);
      },

      export_refunds: () => {
        return this._call('GET', `${baseUrl}/export-refunds/`, null, true);
      },

      export_invoices: () => {
        return this._call('GET', `${baseUrl}/export-invoices/`, null, true);
      },
    };
  }

  public get invoice_archives() {
    const baseUrl = '/api/invoice-archives';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      download: (id, inline) => {
        const jwt = this.authService.getToken();
        const obj = {
          token: jwt,
          inline
        };

        return this.config.apis.shiva + `${baseUrl}/${id}/download/${this.api.buildQueryString(obj)}`;
      }
    };
  }

  public get invoice_groups() {
    const baseUrl = '/api/invoice-groups';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get invoice_items() {
    const baseUrl = '/api/invoice-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get invoice_tax_lines() {
    return {
      list: (filters) => {
        return this._call('GET', `/api/invoice-tax-lines/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public get accounts() {
    const baseUrl = '/api/accounts';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      }
    };
  }

  public get currencies() {
    const baseUrl = '/api/currencies';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      }
    };
  }

  public get fiscal_positions() {
    const baseUrl = '/api/fiscal-positions';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      }
    };
  }

  public get fiscal_position_items() {
    const baseUrl = '/api/fiscal-position-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get taxes() {
    const baseUrl = '/api/taxes';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      }
    };
  }

  public get payment_terms() {
    const baseUrl = '/api/payment-terms';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      }
    };
  }

  public get binders() {
    const baseUrl = '/api/binders';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      addInvoices: (id, invoiceCodeList) => {
        return this._call('POST', `${baseUrl}/${id}/invoices/`, {invoices_code: invoiceCodeList});
      },
      removeInvoices: (id, invoiceCodeList) => {
        return this._call('POST', `${baseUrl}/${id}/remove-invoices/`, {invoices_code: invoiceCodeList});
      },
      cancelAll: (id) => {
        return this._call('POST', `${baseUrl}/${id}/cancel-all/`);
      },
      downloadUrl: (id, type): string => {
        const jwt = this.authService.getToken();

        let endpoint = 'download';

        if (type) {
          endpoint = `${endpoint}_${type}`;
        }
        return this.config.apis.shiva + `${baseUrl}/${id}/${endpoint}/${this.api.buildQueryString({token: jwt})}`;
      }
    };
  }

  public get payment_methods() {
    const baseUrl = '/api/payment-methods';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      }
    };
  }

  public get payment_schedules() {
    return {
      list: (filters) => {
        return this._call('GET', `/api/payment-schedules/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public get billing_items() {
    return {
      list: (filters) => {
        return this._call('GET', `/api/billing-items/${this.api.buildQueryString(filters)}`);
      }};
  }

  public get order() {
    return {
      // Create an invoice from orders_id
      create: (data) => {
        return this._call('POST', `/api/invoices/invoice_orders/`, data);
      }
    };
  }

  public get sage_exports() {
    const baseUrl = '/api/sage-exports';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      collect_invoices: (id) => {
        return this._call('POST', `${baseUrl}/${id}/collect_invoices/`);
      },
      export_entities: (taskName, inline) => {
        const jwt = this.authService.getToken();
        const obj = {
          token: jwt,
          task_name: taskName,
          ...(inline && {inline: true}),
        };
        return this._call('GET', `/api/entities/sage-export-entities/${this.api.buildQueryString(obj)}`);
      },
      download: (id, inline) => {
        const jwt = this.authService.getToken();
        const obj = {
          token: jwt,
          inline
        };

        return this.config.apis.shiva + `${baseUrl}/${id}/download/${this.api.buildQueryString(obj)}`;
      }
    };
  }

  public get sage_export_items() {
    return {
      list: (filters) => {
        return this._call('GET', `/api/sage-export-items/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public get refund_sage_exports() {
    const baseUrl = '/api/refund-sage-exports';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      download: (id, inline) => {
        const jwt = this.authService.getToken();
        const obj = {
          token: jwt,
          inline
        };

        return this.config.apis.shiva + `${baseUrl}/${id}/download/${this.api.buildQueryString(obj)}`;
      }
    };
  }

  public get refund_sage_export_items() {
    return {
      list: (filters) => {
        return this._call('GET', `/api/refund-sage-export-items/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public get invoice_send_events() {
    const baseUrl = '/api/invoice-send-events';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      build: (id) => {
        return this._call('POST', `${baseUrl}/${id}/build/`);
      },
      send: (id) => {
        return this._call('POST', `${baseUrl}/${id}/send/`);
      }
    };
  }

  public get invoice_send_items() {
    const baseUrl = '/api/invoice-send-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      }
    };
  }

  public get invoice_transmissions() {
    const baseUrl = '/api/invoice-transmissions';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id: number) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      export: (payload: { company_codes: string[] }) => {
        return this._call<IJobResponse>('POST', `${baseUrl}/export/`, payload);
      },
      resend: (id: number) => {
        return this._call<IJobResponse>('POST', `${baseUrl}/${id}/resend/`, {});
      },
    };
  }

  public get product_accounts() {
    const baseUrl = '/api/product-accounts';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get order_invoice_builders() {
    const baseUrl = '/api/order-invoice-builders';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (orderCode) => {
        const filters = {
          order__code: orderCode
        };
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload, true);
      }
    };
  }

  public get control_files() {
    const baseUrl = '/api/control-files';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      generateFile: (id) => {
        return this._call('GET', `${baseUrl}/${id}/generate-file/`);
      }
    };
  }

  public get attachments() {
    const baseUrl = '/api/attachments';
    return {
      list: (model, id, filters) => {
        console.warn('depreciated attachments call !');
        return this._call('GET', `${baseUrl}/${model}:${id}/${this.api.buildQueryString(filters)}`);
      },
      upload: (model, id) => {
        console.warn('depreciated attachments call !');
        return this.config.apis.shiva + `${baseUrl}/${model}:${id}/`;
      },
      update: (id, payload) => {
        console.warn('depreciated attachments call !');
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      delete: (id) => {
        console.warn('depreciated attachments call !');
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      downloadUrl: (id) => {
        console.warn('depreciated attachments call !');
        const token = this.authService.getToken();
        return this.config.apis.shiva + `${baseUrl}/${id}/download/?token=${token}`;
      },
      download_text: (id) => {
        console.warn('depreciated attachments call !');
        const token = this.authService.getToken();
        return this._call('GET',  `${baseUrl}/${id}/download/?token=${token}`, undefined, undefined , 'text');
      }
    };
  }


  /**
   * New, permissions-permitting attachments api.
   *
   * this will eventually replace the above method ^
   *
  **/
  public get attachments_ng() {
    return {
      list: (model, id, filters) => {
        return this._call('GET', `/api/${model}/${id}/attachment-list/${this.api.buildQueryString(filters)}`);
      },
      upload: (model, id) => {
        return this.config.apis.shiva + `/api/${model}/${id}/attachment-upload/`;
      },
      update: (model, id, attachment_id, payload) => {
        return this._call('PATCH', `/api/${model}/${id}/attachment-update/${attachment_id}/`, payload);
      },
      delete: (model, id, attachment_id) => {
        return this._call('DELETE', `/api/${model}/${id}/attachment-delete/${attachment_id}/`);
      },
      downloadUrl: (model, id, attachment_id) => {
        const token = this.authService.getToken();
        return this.config.apis.shiva + `/api/${model}/${id}/attachment-download/${attachment_id}/?token=${token}`;
      },
      download_text: (model, id, attachment_id) => {
        const token = this.authService.getToken();
        return this._call('GET',  `/api/${model}/${id}/attachment-download/${attachment_id}/?token=${token}`, undefined, undefined , 'text');
      }
    };
  }

  public get companies() {
    const baseUrl = '/api/companies';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },
      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (code) => {
        return this._call('DELETE', `${baseUrl}/${code}/`);
      }
    };
  }

  public get entities() {
    const baseUrl = '/api/entities';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      disabled_list: (filters) => {
        return this._call('GET', `${baseUrl}/disabled/${this.api.buildQueryString(filters)}`);
      },

      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },

      close_site: (code, disableError?) => {
        return this._call('POST', `${baseUrl}/${code}/close_site/`, null, disableError);
      },

      open_site: (code, disableError?) => {
        return this._call('POST', `${baseUrl}/${code}/open_site/`, null, disableError);
      },

      disable_site: (code, disableError?) => {
        return this._call('POST', `${baseUrl}/${code}/disable_site/`, null, disableError);
      },

      enable_site: (code, disableError?) => {
        return this._call('POST', `${baseUrl}/${code}/enable_site/`, null, disableError);
      },

      enable_sf_sync: (code, disableError?) => {
        return this._call('POST', `${baseUrl}/${code}/enable-sf-sync/`, null, disableError);
      },

      merge: (code, payload, disableError?) => {
        return this._call('POST', `${baseUrl}/${code}/merge/`, payload, disableError);
      },

      create: (payload, disableError, isWizard) => {
        const additionalQuery = isWizard ? '?serializer=entity_wizard' : '';
        return this._call('POST', `${baseUrl}/${additionalQuery}`, payload, disableError);
      },

      update: (code, payload, disableError, isWizard) => {
        const additionalQuery = isWizard ? '?serializer=entity_wizard' : '';
        return this._call('PATCH', `${baseUrl}/${code}/${additionalQuery}`, payload, disableError);
      },

      check_customer_ref: (code, customerRef, disableError?) => {
        const payload = {customer_ref: customerRef};
        return this._call('POST', `${baseUrl}/${code}/check_customer_ref/`, payload, disableError);
      },

      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },

      export_portal_account: (filters) => {
        return this._call('POST', `${baseUrl}/export_portal_account/${this.api.buildQueryString(filters)}`, null, true);
      },

      export_opening_hours: (filters) => {
        return this._call('POST', `${baseUrl}/export-opening-hours/${this.api.buildQueryString(filters)}`, null, true);
      },

      import_opening_hours: () => {
        return this.config.apis.shiva + `${baseUrl}/import-opening-hours/`;
      },

      download_mandate_url: (code, inline = true) => {
        const token = this.authService.getToken();
        const query = {
          token,
          inline
        };
        return this.config.apis.shiva + `${baseUrl}/${code}/download-mandate/${this.api.buildQueryString(query)}`;
      },

      copy_location: (code, type) => {
        const payload = {location_type: type};
        return this._call('POST', `${baseUrl}/${code}/copy-default-location/`, payload);
      },

      unique_affiliate_refs: () => {
        return this._call('GET', `${baseUrl}/unique-affiliate-refs/`);
      },

      unique_brands: () => {
        return this._call('GET', `${baseUrl}/unique-brands/`);
      },

      unique_invoice_group_tags: () => {
        return this._call('GET', `${baseUrl}/unique-invoice-group-tags/`);
      },

      payment_status: (code) => {
        return this._call('GET', `${baseUrl}/${code}/get-payment-alert/`);
      }
    };
  }

  public get contacts() {
    const baseUrl = '/api/contacts';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },

      assign: (code, payload) => {
        return this._call('POST', `${baseUrl}/${code}/assign-to-sites/`, payload);
      },

      contacts_from_xlsx_import_url: () => {
        return this.config.apis.shiva + `${baseUrl}/import-contacts/`;
      },

    };
  }

  public get contact_entity_relationships() {
    const baseUrl = '/api/contact-entity-relationships';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },

      export_address_book: (filters) => {
        return this._call('POST', `${baseUrl}/export-address-book-relations/${this.api.buildQueryString(filters)}`, null, true);
      },

      export_direct: (filters) => {
        return this._call('POST', `${baseUrl}/export-direct-relations/${this.api.buildQueryString(filters)}`, null, true);
      },

      import_address_book_relations: () => {
        return this.config.apis.shiva + `${baseUrl}/import-address-book-relations/`;
      },

      import_direct_relations: () => {
        return this.config.apis.shiva + `${baseUrl}/import-direct-relations/`;
      },
    };
  }

  public get locations() {
    const baseUrl = '/api/locations';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },

      geolocalize: (pk: number): Promise<void> => {
        return this._call('POST', `${baseUrl}/${pk}/geolocalize/`) as Promise<void>;
      },
    };
  }

  public get quotes() {
    const baseUrl = '/api/quotes';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      create_version: (id) => {
        return this._call('POST', `${baseUrl}/${id}/create_version/`);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      download: (id, inline) => {
        const jwt = this.authService.getToken();
        const obj = {
          token: jwt,
          ...(inline && {inline: true})
        };
        return this.config.apis.shiva + `${baseUrl}/${id}/download/${this.api.buildQueryString(obj)}`;
      },

      create_from_salesforce: (payload) => {
        return this._call('POST', `${baseUrl}/create-from-sf/`, payload);
      },

      importItemsUrl: (id) => {
        return this.config.apis.shiva + `${baseUrl}/${id}/import-items/`;
      },

      exportItemsUrl: (id) => {
        return this._call('POST', `${baseUrl}/${id}/export-items/`);
      },
    };
  }

  public get quote_groups() {
    const baseUrl = '/api/quote-groups';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      duplicate: (id) => {
        return this._call('POST', `${baseUrl}/${id}/duplicate/`);
      },
      delete_cascade: (id) => {
        return this._call('POST', `${baseUrl}/${id}/delete-cascade/`);
      }
    };
  }

  public get quote_items() {
    const baseUrl = '/api/quote-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      duplicate: (id) => {
        return this._call('POST', `${baseUrl}/${id}/duplicate/`);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get price_books() {
    const baseUrl = '/api/price-books';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      products: (id) => {
        return this._call('GET', `${baseUrl}/${id}/products/`);
      },
      product_configs_get: (id, productCode, filters) => {
        return this._call('GET', `${baseUrl}/${id}/configs:${productCode}/${this.api.buildQueryString(filters)}`);
      },
      product_configs_set: (id, productCode, payload) => {
        return this._call('POST', `${baseUrl}/${id}/configs:${productCode}/`, payload);
      },
      add_entities: (id, entitiesCodes) => {
        const payload = {
          entities_code: entitiesCodes
        };
        return this._call('POST', `${baseUrl}/${id}/add-entities/`, payload, true);
      },
      remove_entity: (id, entityCode) => {
        const payload = {
          entities_code: [entityCode]
        };
        return this._call('POST', `${baseUrl}/${id}/remove-entities/`, payload, true);
      },
      replace_active_pricebook: (id, keptEntityCodes) => {
        const payload = {
          kept_entity_codes: keptEntityCodes
        };
        return this._call('POST', `${baseUrl}/${id}/replace-active-pricebook/`, payload, true);
      },
      check_for_activation: (id) => {
        return this._call('POST', `${baseUrl}/${id}/check-for-activation/`);
      },
      export_product_configs_items: (code, productIds) => {
        const payload = {
          product_ids: productIds
        };
        return this._call('POST', `${baseUrl}/${code}/export-items/`, payload, true);
      },
      import_product_configs_items: (code) => {
        return this.config.apis.shiva + `${baseUrl}/${code}/import-items/`;
      }
    };
  }

  public get price_book_items() {
    const baseUrl = '/api/price-book-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get entity_bulk_imports() {
    const baseUrl = '/api/entity-bulk-imports';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      create_url: () => {
        return this.config.apis.shiva + `${baseUrl}/`;
      },
      validate: (id) => {
        return this._call('POST', `${baseUrl}/${id}/validate/`);
      },
      process: (id) => {
        return this._call('POST', `${baseUrl}/${id}/process/`);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get entity_types() {
    const baseUrl = '/api/entity-types';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id, disableError?) => {
        return this._call('DELETE', `${baseUrl}/${id}/`, null, disableError);
      }
    };
  }

  public get entity_available_types() {
    const baseUrl = '/api/entity-available-types';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id, disableError?) => {
        return this._call('DELETE', `${baseUrl}/${id}/`, null, disableError);
      },
      migrate: (id, newType, disableError?) => {
        const payload = {
          'new-entity-available-type': newType
        };
        return this._call('POST', `${baseUrl}/${id}/migrate/`, payload, disableError);
      }
    };
  }

  public get entity_alerts() {
    const baseUrl = '/api/entity-alerts';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
    };
  }

  public get contractors() {
    const baseUrl = '/api/contractors';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      addZipcode: (id, payload) => {
        return this._call('POST', `${baseUrl}/${id}/add-zipcode/`, payload);
      },
      removeZipcode: (id, payload) => {
        return this._call('POST', `${baseUrl}/${id}/remove-zipcode/`, payload);
      },
      importZipcodesCsv: (id, fileCsv) => {
        const formData = new FormData();
        formData.append('file', fileCsv, fileCsv.name);
        return this._call('POST', `${baseUrl}/${id}/import-zipcodes/`, formData);
      }
    };
  }

  public get templates() {
    const baseUrl = '/api/templates';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}`);
      },
      detailByName: (name: string) => {
        return this._call('GET', `${baseUrl}/${name}/by_name/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get exports() {
    return {
      products: () => {
        const token = this.authService.getToken();
        return this.config.apis.shiva + `/api/exports/export-products/?token=${token}`;
      },
      entities: () => {
        const token = this.authService.getToken();
        return this.config.apis.shiva + `/api/exports/export-entities/?token=${token}`;
      }
    };
  }

  public get orders() {
    const baseUrl = '/api/mrp-orders';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      bulk_update: (idList, updatePayload, comment) => {
        const payload = {ids: idList, payload: updatePayload, comment};
        return this._call('PATCH', `${baseUrl}/bulk-update/`, payload);
      },
      bulk_update_project: (idList, updatePayload, comment) => {
        const payload = {ids: idList, payload: updatePayload, comment};
        return this._call('PATCH', `${baseUrl}/bulk-update-project/`, payload, true);
      },
      bulk_update_customer_ref: (idList, customerRef) => {
        const payload = {ids: idList, customer_ref: customerRef};
        return this._call('PATCH', `${baseUrl}/bulk-update-customer-ref/`, payload, true);
      },
      bulk_update_provisional_end_date: (idList: number[], provisionalEndDate: Date) => {
        const payload = {ids: idList, provisional_end_date: provisionalEndDate};
        return this._call('PATCH', `${baseUrl}/bulk-update-provisional-end-date/`, payload, true);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      unique_references: (entityCode) => {
        const paramObj = {entity_code: entityCode};
        return this._call('GET', `${baseUrl}/unique_references/${this.api.buildQueryString(paramObj)}`);
      },
      export: (filters, addComments?) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, {add_comments: addComments}, true);
      },
      export_order_items: (filters) => {
        return this._call('POST', `${baseUrl}/export-order-items/${this.api.buildQueryString(filters)}`, null, true);
      },
      invoiceable_forecast: (filters) => {
        return this._call('GET', `${baseUrl}/invoiceable_forecast/${this.api.buildQueryString(filters)}`);
      },
      early_invoicing: (id, payload) => {
        return this._call('POST', `${baseUrl}/${id}/early_invoicing/`, payload);
      },
      early_activation: (id, payload) => {
        return this._call('POST', `${baseUrl}/${id}/early_activation/`, payload);
      },
      procurement: (code) => {
        return this._call('POST', `${baseUrl}/${code}/procurement/`);
      },
    };
  }

  public get order_groups() {
    const baseUrl = '/api/mrp-order-groups';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get order_groups_invoicing() {
    return {
      list: (filters) => {
        return this._call('GET', `/api/order-groups/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public get order_items() {
    const baseUrl = '/api/mrp-order-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (data) => {
        return this._call('POST', `${baseUrl}/`, data);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      refreshConfig: (id) => {
        return this._call('POST', `${baseUrl}/${id}/refresh-product-config/`);
      }
    };
  }


  public get logo() {
    const baseUrl = '/api/logo';
    return {
      get_logo_url: (id) => {
        const jwt = this.authService.getToken();
        return this.config.apis.shiva + `${baseUrl}/${id}/img_data/${this.api.buildQueryString({token: jwt})}`;
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      }
    };
  }

  public get provider_traceabilities() {
    const baseUrl = '/api/provider-traceabilities';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },

      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`);
      },

      bulk_update_finance: () => {
        return this.config.apis.shiva + `${baseUrl}/bulk-update-finance/`;
      },
    };
  }

  public get traceabilities() {
    const baseUrl = '/api/traceabilities';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      terminate: (id, date) => {
        const payload = {date};
        return this._call('POST', `${baseUrl}/${id}/terminate/`, payload);
      },

      bulk_terminate: () => {
        return this.config.apis.shiva + `${baseUrl}/bulk-terminate/`;
      },

      bulk_update: () => {
        return this.config.apis.shiva + `${baseUrl}/bulk-update/`;
      },

      reactivate: (id) => {
        return this._call('POST', `${baseUrl}/${id}/reactivate/`);
      },

      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`);
      },

      export_provider_traceabilities: (filters) => {
        return this._call('POST', `${baseUrl}/export-provider-traceabilities/${this.api.buildQueryString(filters)}`);
      },

      export_renewal_services: (filters) => {
        return this._call('POST', `${baseUrl}/export-renewal-services/${this.api.buildQueryString(filters)}`);
      },

      transfer_traceabilities_url: (): string => {
        return this.config.apis.shiva + `${baseUrl}/transfer-traceabilities/`;
      }

    };
  }

  public get traceability_order_item_relationships() {
    const baseUrl = '/api/traceability-order-item-relationships';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      }
    };
  }

  public get sequences() {
    const baseUrl = '/api/sequences';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      }

    };
  }

  public get products() {
    const baseUrl = '/api/products';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },

      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },

      activate: (code) => {
        return this._call('POST', `${baseUrl}/${code}/activate/`);
      },

      deactivate: (code) => {
        return this._call('POST', `${baseUrl}/${code}/deactivate/`);
      },

      import_configuration_url: (code) => {
        return this.config.apis.shiva + `${baseUrl}/${code}/import-configuration/`;
      },

      download_configurations_url: (code) => {
        const obj = {
          token: this.authService.getToken()
        };

        return this.config.apis.shiva + `${baseUrl}/${code}/download-configurations/${this.api.buildQueryString(obj)}`;
      },

      download_parameters_url: (code) => {
        const obj = {
          token: this.authService.getToken()
        };

        return this.config.apis.shiva + `${baseUrl}/${code}/download-parameters/${this.api.buildQueryString(obj)}`;
      },

      merged_configs: (productCode, entityCode, filters?) => {
        // return the price book configs merged with the product configs
        return this._call('GET', `${baseUrl}/${productCode}/configs:${entityCode}/${this.api.buildQueryString(filters)}`);
      }

    };
  }

  public get product_taxes() {
    const baseUrl = '/api/products';
    return {
      list: (code, filters) => {
        return this._call('GET', `${baseUrl}/${code}/taxes/${this.api.buildQueryString(filters)}`);
      },
      create: (code, taxId) => {
        return this._call('POST', `${baseUrl}/${code}/taxes/?id=${taxId}`);
      },
      delete: (code, taxId) => {
        return this._call('DELETE', `${baseUrl}/${code}/taxes/?id=${taxId}`);
      }
    };
  }

  public get bill_of_materials() {
    const baseUrl = '/api/bill-of-materials';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },

      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },

      activate: (code) => {
        return this._call('POST', `${baseUrl}/${code}/activate/`);
      },

      duplicate: (code) => {
        return this._call('POST', `${baseUrl}/${code}/duplicate/`);
      },

      execute: (code, payload) => {
        return this._call('POST', `${baseUrl}/${code}/execute/`, payload);
      },

      switch_is_active: (id, newState) => {
        const payload = {is_active: newState};
        return this._call('POST', `${baseUrl}/${id}/switch-is-active/`, payload);
      }
    };
  }

  public get bill_of_materials_items() {
    const baseUrl = '/api/bill-of-materials-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },

      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get procurement_modules() {
    return {
      list: () => {
        return this._call('GET', '/api/procurement-modules/');
      }
    };
  }

  public get product_categories() {
    const baseUrl = '/api/product-categories';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      }
    };
  }

  public get product_configurations() {
    const baseUrl = '/api/product-configurations';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },

      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },
    };
  }

  public get product_parameters() {
    const baseUrl = '/api/product-parameters';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },

      update: (code, payload) => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },

      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },

      from_json: () => {
        return this.config.apis.shiva + `${baseUrl}/from_json/`;
      },
    };
  }

  public get tags_history() {
    return {
      list: (contentTypeModel, contentId) => {
        const filters = {
          content_type__model: contentTypeModel,
          content_id: contentId
        };
        return this._call('GET', `/api/tags-history/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public comments(model, pk) {
    const baseUrl = `/api/comments/${model}:${pk}`;
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      create: (text: string, is_public?: boolean) => {
        const payload = {
          text: text,
          is_public: is_public
        };
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      destroy: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get work_order_items() {
    const baseUrl = '/api/work-order-items';
    return {
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      duplicate: (id, payload) => {
        return this._call('POST', `${baseUrl}/${id}/duplicate/`, payload);
      },

      bulk_update: (idList, payload, comment?) => {
        return this._call('PATCH', `${baseUrl}/bulk-update/`, {ids: idList, payload, comment});
      },

      bulk_close: (idList, endState, taskName, completionDate, comment) => {
        const payload = {
          ids: idList,
          state: endState,
          name: taskName,
          completion_date: completionDate,
          comment
        };
        return this._call('POST', `${baseUrl}/bulk-close/`, payload);
      },

      bulk_patch_tags: (idList: string[], addedTags: ITags[], removedTags: ITags[], taskName: string): Promise<ITagBulkPatchResponse> => {
        const payload = {
          ids: idList,
          addedTags,
          removedTags,
          name: taskName
        };
        return this._call('POST', `${baseUrl}/bulk-patch-tags/`, payload);
      },

      list: (filters): Promise<IListResult<IWorkOrderItems>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      relations_list: (id) => {
        return this._call('GET', `${baseUrl}/${id}/relations/`);
      },

      relations_create: (id, payload) => {
        return this._call('POST', `${baseUrl}/${id}/relations/`, payload);
      },

      relations_delete: (woiId, relId) => {
        return this._call('DELETE', `${baseUrl}/${woiId}/relations/?id=${relId}`);
      },

      relations_ratio: (id) => {
        return this._call('GET', `${baseUrl}/${id}/relations-ratio/`);
      },

      process_fop_address: (id, disableItemUpdate) => {
        const payload = {
          disable_item_update : disableItemUpdate ? true : false
        };
        return this._call('POST', `${baseUrl}/${id}/process_fop_address/`, payload);
      },

      export: (filters) => {
        return this._call('POST', `${baseUrl}/export-tasks/${this.api.buildQueryString(filters)}`, null, true);
      },

      exportSelected: (ids) => {
        const payload = {
          tasks_ids: ids,
        };
        return this._call('POST', `${baseUrl}/export-tasks/`, payload, true);
      },

      bulk_patch_import_url: () => {
        return this.config.apis.shiva + `${baseUrl}/bulk-update-xlsx/`;
      },

      bulk_patch_mep_import_url: () => {
        return this.config.apis.shiva + `${baseUrl}/bulk-patch-mep/`;
      },

      bulk_update_metadata: (idList, payload) => {
        return this._call('PATCH', `${baseUrl}/bulk-update-metadata/`, {ids: idList, payload});
      },

      get_shipping_mail_data: (id: number) => {
        return this._call('GET', `${baseUrl}/${id}/get-shipping-mail-data/`);
      },

      bulk_play_transition: (workflow: string, source: string, destination: string, selectedIds: string[]) => {
        const payload = {
          destination: destination,
          select_ids: selectedIds,
          source: source,
          workflow: workflow};
        return this._call('POST', `${baseUrl}/bulk-play-transition/`, payload, true);
      },

      send_mail: (id: number, payload: {}) => {
        return this._call('POST', `${baseUrl}/${id}/send-mail/`, payload);
      },

      provisionning_p_flash: (id: number, payload: {}) => {
        return this._call('POST', `${baseUrl}/${id}/provisionning-p-flash/`, payload);
      },

      cancelOrder: (id: number): Promise<IWorkOrderItems> => {
        return this._call('POST', `${baseUrl}/${id}/cancel-order/`);
      },

      interventions: (filters: IFilters): Promise<IWOIIntervention[]> => {
        return this._call('GET', `${baseUrl}/interventions/${this.api.buildQueryString(filters)}`);
      },
    };
  }

  public get work_orders() {
    const baseUrl = '/api/work-orders';
    return {
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },

    };
  }

  public get configurable_menus() {
    const baseUrl = '/api/configurable-menus';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      remove: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },

      // This method is used only for the personal menu, when we create a bookmark
      // the backend will automatically link it to the right menu (and create it if necessary)
      add_personal_menu: (payload) => {
        return this._call('POST', `${baseUrl}/add-personal-menu/`, payload);
      },
    };
  }

  public get equipments() {
    const baseUrl = '/api/equipments';
    return {
      list: (filters: IFilters): Promise<IListResult<IEquipment>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id): Promise<IEquipment> => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload, disableError?): Promise<IEquipment[]> => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },

      update: (id, payload): Promise<IEquipment> => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },

      compute_total_price: (filters) => {
        return this._call('GET', `${baseUrl}/compute_total_price/${this.api.buildQueryString(filters)}`);
      },

      bulk_assign: (payload) => {
        return this._call('POST', `${baseUrl}/bulk-assign/`, payload);
      },

      bulk_update: () => {
        return  this.config.apis.shiva + `${baseUrl}/bulk-update/`;
      },

      bulk_delete: (filters) => {
        return this._call('POST', `${baseUrl}/bulk-delete/${this.api.buildQueryString(filters)}`, null, true);
      },

      assign_tags: (payload) => {
        return this._call('POST', `${baseUrl}/assign-tags/`, payload);
      },

      remove_tags: (payload) => {
        return this._call('POST', `${baseUrl}/remove-tags/`, payload);
      },

      remove_lr: (payload) => {
        return this._call('POST', `${baseUrl}/remove-logistics-request/`, payload);
      },

    };
  }

  public get equipment_models() {
    const baseUrl = '/api/equipment-models';
    return {
      list: (filters): Promise<IListResult<IEquipmentModel>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id): Promise<IEquipmentModel> => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      delete: (id, disableError?) => {
        return this._call('DELETE', `${baseUrl}/${id}/`, null, disableError);
      },

      export: (filters, fields: string[]) => {
        const jwt = this.authService.getToken();
        const queryString = {
          token: jwt,
          ...(fields.length > 0 && {fields}),
          ...filters,
        };
        return this.config.apis.shiva + `${baseUrl}/csv/${this.api.buildQueryString(queryString)}`;
      },

      reset_stock_location: (id) => {
        return this._call('POST', `${baseUrl}/${id}/reset-stock-location/`);
      },

      inventory: (id, serialNumbersStr, owner, reservedFor, disableError?) => {
        const payload = {
          serial_numbers: serialNumbersStr,
          owner,
          reserved_for: reservedFor
        };
        return this._call('POST', `${baseUrl}/${id}/inventory/`, payload, disableError);
      },

      merge: (id, payload, disableError?) => {
        return this._call('POST', `${baseUrl}/${id}/merge/`, payload, disableError);
      },

      reset_stock_count: (id, count, disableError?) => {
        const payload = {count};
        return this._call('POST', `${baseUrl}/${id}/reset-stock-count/`, payload, disableError);
      }

    };
  }

  public get equipment_model_mappings() {
    const baseUrl = '/api/equipment-model-mapping';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
    };
  }

  public get equipment_constructors() {
    const baseUrl = '/api/equipment-constructors';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      }

    };
  }

  public get equipment_records() {
    const baseUrl = '/api/equipment-records';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      entityHistory: (entityCode) => {
        return this._call('GET', `${baseUrl}/${entityCode}/entity/`);
      }

    };
  }

  public get externalEquipmentOrders() {
    const baseUrl = '/api/external-equipment-orders';
    return {
      list: (filters: IFilters): Promise<IListResult<IExternalEquipmentOrder>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id: number): Promise<IExternalEquipmentOrder> => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      create: (payload: IExternalEquipmentOrder): Promise<IExternalEquipmentOrder> => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      update: (id: number, payload: IExternalEquipmentOrder): Promise<IExternalEquipmentOrder> => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      randomSlipNumber: (): Promise<IRandomSlipNumberResponse> => {
        return this._call('GET', `${baseUrl}/random-slip-number/`);
      },
      create_equipments: (id: number): Promise<IReceiveEquipmentResponse> => {
        return this._call('POST', `${baseUrl}/${id}/create-equipments/`);
      }
    };
  }

  public get externalEquipmentOrderItems() {
    const baseUrl = '/api/external-equipment-order-items';
    return {
      list: (filters: IFilters): Promise<IListResult<IExternalEquipmentOrderItem>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail:(id: number): Promise<IExternalEquipmentOrderItem> => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      create: (payload: IExternalEquipmentOrderItem): Promise<IExternalEquipmentOrderItem> => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      update: (id: number, payload: IExternalEquipmentOrderItem): Promise<IExternalEquipmentOrderItem> => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      }
    };
  }

  public get tags() {
    const baseUrl = '/api/tags';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      importTagsUrl: () => {
        return this.config.apis.shiva + `${baseUrl}/import-tags/`;
      },
    };
  }

  public get providers() {
    const baseUrl = '/api/providers';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      switch_is_active: (id, newState) => {
        const payload = {is_active: newState};
        return this._call('POST', `${baseUrl}/${id}/switch-is-active/`, payload);
      }
    };
  }

  public get technologies() {
    const baseUrl = '/api/technologies';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      switch_is_active: (id, newState) => {
        const payload = {is_active: newState};
        return this._call('POST', `${baseUrl}/${id}/switch-is-active/`, payload);
      }
    };
  }

  public get offers() {
    const baseUrl = '/api/offers';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (code) => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },
      update: (id, payload, disableError?) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload, disableError);
      },
      create: (payload, disableError?) => {
        return this._call('POST', `${baseUrl}/`, payload, disableError);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      add_collection_nodes: (id, collectionNodesCodes) => {
        const payload = {
          collection_nodes_codes: collectionNodesCodes
        };
        return this._call('POST', `${baseUrl}/${id}/add-collection-nodes/`, payload, true);
      },
      remove_collection_node: (id, collectionNodeCode) => {
        const payload = {
          collection_nodes_codes: [collectionNodeCode]
        };
        return this._call('POST', `${baseUrl}/${id}/remove-collection-nodes/`, payload, true);
      },
      switch_is_active: (id, newState, disableError?) => {
        const payload = {is_active: newState};
        return this._call('POST', `${baseUrl}/${id}/switch-is-active/`, payload, disableError);
      }
    };
  }

  public get project_items() {
    const baseUrl = '/api/project-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get project_indicators() {
    const baseUrl = '/api/project-indicators';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get projects() {
    const baseUrl = '/api/projects';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      add_entities : (id, entitiesCodes) => {
        const payload = {
          entity_codes: entitiesCodes
        };
        return this._call('POST', `${baseUrl}/${id}/add-entities/`, payload);
      },
      remove_entity : (id, entityCode, disableError?) => {
        const payload = {
          entity_codes: [entityCode]
        };
        return this._call('POST', `${baseUrl}/${id}/remove-entities/`, payload, disableError);
      },
      sites: (projectCode, filters) => {
        return this._call('GET', `${baseUrl}/${projectCode}/sites/${this.api.buildQueryString(filters)}`);
      },
    };
  }

  public get project_equipment_model_relationships() {
    const baseUrl = '/api/project-equipment-model-relationships';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get models_history() {
    return {
      list: (model, id) => {
        const filters = {
          content_type_name: model,
          content_id: id
        };
        return this._call('GET', `/api/models-history/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public get long_running_jobs() {
    const baseUrl = '/api/long-running-jobs';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      }
    };
  }

  public get translations() {
    const baseUrl = '/api/translations';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get accounting_equipments() {
    const baseUrl = '/api/accounting-equipments';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      export: (filters) => {
        return this._call('POST', `${baseUrl}/export/${this.api.buildQueryString(filters)}`, null, true);
      },
    };
  }

  public get provider_orders() {
    const baseUrl = '/api/provider-orders';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      import_url: () => {
        return this.config.apis.shiva + `${baseUrl}/provider-orders-from-csv/`;
      },
      available_equipments: (id, modelId) => {
        return this._call('GET', `${baseUrl}/${id}/available-equipments/${modelId}/`);
      }
    };
  }

  public get provider_invoices() {
    const baseUrl = '/api/provider-invoices';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      import_url: () => {
        return this.config.apis.shiva + `${baseUrl}/provider-invoices-from-csv/`;
      },
    };
  }

  public get logistics_requests() {
    const baseUrl = '/api/logistics-requests';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
      download_pdf_labels: (code) => {
        return this._call('GET', `${baseUrl}/${code}/download-pdf-labels/`);
      },
      download_recuperation_pdf_label: (code) => {
        return this.config.apis.shiva + `${baseUrl}/${code}/get-recuperation-pdf-label/`;
      },
      generate_customs_pdf : (code, payload) => {
        return this._call('POST', `${baseUrl}/${code}/generate-customs-pdf/`, payload);
      },
      export_logistics_requests: (filters) => {
        return this._call<IJobResponse>('POST', `${baseUrl}/export-logistics-requests/${this.api.buildQueryString(filters)}`, null, true);
      },
      export_logistics_request_management: (filters) => {
        return this._call<IJobResponse>('POST', `${baseUrl}/export-logistics-request-management/${this.api.buildQueryString(filters)}`, null, true);
      },
      import_logistics_requests: () => {
        return this.config.apis.shiva + `${baseUrl}/import-logistics-requests/`;
      },
      bulk_start: (idList, taskName) => {
        const payload = {
          ids: idList,
          name: taskName,
        };
        return this._call('POST', `${baseUrl}/bulk-start/`, payload);
      },
      bulk_update: (idList, taskName, update) => {
        const payload = {
          ids: idList,
          name: taskName,
          update,
        };
        return this._call('POST', `${baseUrl}/bulk-update/`, payload);
      },
      bulk_cancel: (idList, taskName) => {
        const payload = {
          ids: idList,
          name: taskName,
        };
        return this._call('POST', `${baseUrl}/bulk-cancel/`, payload);
      },
      bulk_patch_tags: (idList: string[], addedTags: ITags[], removedTags: ITags[], taskName: string): Promise<ITagBulkPatchResponse> => {
        const payload = {
          ids: idList,
          addedTags,
          removedTags,
          name: taskName
        };
        return this._call('POST', `${baseUrl}/bulk-patch-tags/`, payload);
      },
      import_logistics_request_items: () => {
        return this.config.apis.shiva + `${baseUrl}/import-logistics-request-items/`;
      },
    };
  }

  public get logistics_request_items() {
    const baseUrl = '/api/logistics-request-items';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get logistics_sites() {
    const baseUrl = '/api/logistics-sites';
    return {
      list: (filters: IFilters): Promise<IListResult<ILogisticsSite>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (code: string): Promise<ILogisticsSite> => {
        return this._call('GET', `${baseUrl}/${code}/`);
      },
      update: (code: string, payload: ILogisticsSite): Promise<ILogisticsSite> => {
        return this._call('PATCH', `${baseUrl}/${code}/`, payload);
      },
      create: (payload: ILogisticsSite): Promise<ILogisticsSite> => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (code: string): Promise<void> => {
        return this._call('DELETE', `${baseUrl}/${code}/`);
      },
      addCarrierAccounts: (code: string, carrierAccountCodes: string[]): Promise<ILogisticsSite> => {
        const payload = { carrier_account_codes: carrierAccountCodes };
        return this._call('POST', `${baseUrl}/${code}/add-carrier-accounts/`, payload);
      },
      removeCarrierAccount: (code: string, carrierAccountCode: string): Promise<ILogisticsSite> => {
        const payload = { carrier_account_codes: [carrierAccountCode] };
        return this._call('POST', `${baseUrl}/${code}/remove-carrier-accounts/`, payload, true);
      },
    };
  }

  public get equipment_locations() {
    const baseUrl = '/api/equipment-locations';
    return {
      list: (filters: IFilters): Promise<IListResult<IEquipmentLocation>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      update: (id: number, payload: IEquipmentLocation): Promise<IEquipmentLocation> => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload: IEquipmentLocation): Promise<IEquipmentLocation> => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id: number): Promise<void> => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get report_configurations() {
    const baseUrl = '/api/report-configurations';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get reports() {
    return {
      list: (filters) => {
        return this._call('GET', `/api/reports/${this.api.buildQueryString(filters)}`);
      }
    };
  }

  public get template_emails() {
    const baseUrl = '/api/template-emails';
    return {
      list: (filters: IFilters): Promise<IListResult<ITemplateEmail>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id: number): Promise<ITemplateEmail> =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id: number, payload: ITemplateEmail): Promise<ITemplateEmail> => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload: ITemplateEmail): Promise<ITemplateEmail> => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id: number): Promise<void> => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get standard_opening_hours() {
    const baseUrl = '/api/standard-opening-hours';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get technicians() {
    const baseUrl = '/api/technicians';
    return {
      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      detail: (id) =>  {
        return this._call('GET', `${baseUrl}/${id}/`);
      },
      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id) => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      }
    };
  }

  public get waitings() {
    const baseUrl = '/api/waitings';
    return {
      list: (filters): Promise<IListResult<IWaiting>> => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },
      update: (id, payload): Promise<IWaiting> => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },
      create: (payload): Promise<IWaiting> => {
        return this._call('POST', `${baseUrl}/`, payload);
      },
      delete: (id): Promise<void> => {
        return this._call('DELETE', `${baseUrl}/${id}/`);
      },
    };
  }
  public get waitingConfigurations() {
    const baseUrl = '/api/waiting-configurations';
    return {
      list: () => {
        return fromPromise(this._call<IListResult<IWaitingConfiguration>>('GET', `${baseUrl}/`))
          .pipe(map((results: IListResult<IWaitingConfiguration>) => results?.results))
          .toPromise();
      },
      detail: (id: number) => this._call<IWaitingConfiguration>('GET', `${baseUrl}/${id}/`),
      update: (id: number, payload: IWaitingConfiguration) => {
        return this._call<IWaitingConfiguration>('PATCH', `${baseUrl}/${id}/`, payload);
      },
      updatePosition: (id: number, position: number) => {
        return this._call<IWaitingConfiguration>('PATCH', `${baseUrl}/${id}/`, { position });
      },
      create: (payload) => this._call<IWaitingConfiguration>('POST', `${baseUrl}/`, payload),
      delete: (id: number) => this._call<void>('DELETE', `${baseUrl}/${id}/`),
    };
  }

  public get carrierAccounts() {
    const baseUrl = '/api/carrier-accounts';
    return {
      create: (payload) => {
        return this._call('POST', `${baseUrl}/`, payload);
      },

      update: (id, payload) => {
        return this._call('PATCH', `${baseUrl}/${id}/`, payload);
      },

      list: (filters) => {
        return this._call('GET', `${baseUrl}/${this.api.buildQueryString(filters)}`);
      },

      detail: (id) => {
        return this._call('GET', `${baseUrl}/${id}/`);
      },

      get_credentials: (code: string, fields): Promise<ICarrierAccountPassword> => {
        return this._call('GET', `${baseUrl}/${code}/get-credentials/${this.api.buildQueryString(fields)}`);
      },

      set_credentials: (code: string, payload: object) => {
        return this._call('POST', `${baseUrl}/${code}/set-credentials/`, payload);
      },
    };
  }

  // Workflows API

  public workflow(model, id) {
    return this._call('GET', `/api/workflows/${model}:${id}/`);
  }

  public workflow_detail(model) {
    return this._call('GET', `/api/workflows/${model}/`);
  }

  public workflow_graph(model, id) {
    const jwt = this.authService.getToken();
    return this.config.apis.shiva + `/api/workflows/${model}:${id}/graph/${this.api.buildQueryString({token: jwt})}`;
  }

  public transition(model, id, name, context?, disableError?) {
    return this._call('POST', `/api/workflows/${model}:${id}/play/`, {
      transition: name,
      context
    }, disableError);
  }

  public list_workflow_transitions(model, pk) {
    return this._call('GET', `/api/workflows/${model}:${pk}/transitions/`);
  }
}
