<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal' && hasPermissions('Wira:ProviderAdmin')">
        <button type="button" class="btn" [ngClass]="detail.is_active ? 'btn-danger' : 'btn-primary'" (click)="toogleIsActive()">
          <span *ngIf="!detail.is_active">Activer</span>
          <span *ngIf="detail.is_active">Désactiver</span>
        </button>
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.code && hasPermissions('Wira:ProviderAdmin')">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading || errorGeneratorAndSuffixPPP || detail.invalidNumber" *ngIf="detail.code && hasPermissions('Wira:ProviderAdmin')">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Fournisseur <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-provider-field class="wcm-highlight-req"
                [(ngModel)]="detail.provider"
                [disabledColumns] ="{is_active: true}"
                name="provider"
                [disabled]="mode === 'normal' || defaults?.provider?.id || detail.code"
                [required]="true">
        </app-provider-field>
      </div>

      <label class="col-sm-2 control-label">
        Technologie <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-technology-field class="wcm-highlight-req"
                              [disabled]="mode === 'normal'"
                              name="technology"
                              [(ngModel)]="detail.technology"
                              [required]="true">
        </app-technology-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Nom
      </label>
      <div class="col-sm-4">
        <input class="form-control"
                [(ngModel)]="detail.name"
                name="name"
                [disabled]="mode === 'normal'"
                placeholder="{{!detail.code ? (detail.provider?.name || 'Fournisseur')  + ' ' + (detail.technology?.name || 'Technologie') : ''}}"/>
      </div>

      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-4">
        <input class="form-control"
                [(ngModel)]="detail.description"
                [disabled]="mode === 'normal'"
                name="decsription" maxlength="128">
      </div>
    </div>

    <hr>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Type <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select required="true" class="form-control wcm-highlight-req" [(ngModel)]="detail.type"
                [disabled]="mode === 'normal' || detail.code" name="type">
          <option *ngFor="let opt of offerTypeOptions" [value]="opt.value">{{opt.label}}</option>
        </select>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.type === 'ppp' || detail.type === 'dia'">
      <label class="col-sm-2 control-label">
        Préfixe login PPP
      </label>
      <div class="col-sm-4">
        <input class="form-control" [(ngModel)]="detail.prefix_ppp" name="prefix_ppp" [readonly]="mode === 'normal'"/>
      </div>

      <label class="col-sm-2 control-label">
        Suffixe login PPP
      </label>
      <div class="col-sm-4">
        <input  modal class="form-control wcm-highlight-req" [(ngModel)]="detail.suffix_ppp" name="suffix_ppp"
                [readonly]="mode === 'normal'"
                (ngModelChange)="controlGeneratorAndSuffixPPP()"
                [ngClass]="{'wcm-highlight-error': errorGeneratorAndSuffixPPP}"/>

        <div class="error-message" *ngIf="errorGeneratorAndSuffixPPP">
          Renseignez cette valeur quand le générateur de login / password est actif
        </div>
      </div>
    </div>

    <ng-container *ngIf="detail.code">
      <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
        <li [ngbNavItem]="0">
          <a ngbNavLink>Lignes</a>
          <ng-template ngbNavContent>
            <app-lines-list [filters]="lines.filters" [disableUrlUpdate]="true"
                            [disabledColumns]="lines.disabledColumns" *ngIf="tabsStatus[0]">
            </app-lines-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="1">
          <a ngbNavLink>Portes de collecte</a>
          <ng-template ngbNavContent>
            <app-collection-nodes-list [disableUrlUpdate]="true" *ngIf="tabsStatus[1]"
                                        (removeItem)="removeCollectionNode($event)"
                                        [filters]="collectionNodes.filters"
                                        (selectItems)="addCollectionNodes()"
                                        [disabledButtons]="collectionNodes.disabledButtons"
                                        [disabledColumns]="collectionNodes.disabledColumns">
            </app-collection-nodes-list>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="detail.technology?.category?.code === 'TELECOM'">
          <a ngbNavLink>Paramètres</a>
          <ng-template ngbNavContent>
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Produit de procurement
              </label>
              <div class="col-sm-4">
                <app-product-field  [(ngModel)]="detail.procurement_product"
                                    [disabled]="mode === 'normal'" name="procurement_product"
                                    [filters]="{for_sale: true}"
                                    [disabledButtons]="{active: true}">
                </app-product-field>
              </div>
              <label class="col-sm-2 control-label">
                Type d'optique requis
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.has_optical_type" [binary]="true" inputId="hasOpticalTypeId"
                              [disabled]="mode === 'normal'" name="hasOpticalType">
                  </p-checkbox>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Paire requise
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.has_pairs" [binary]="true" inputId="hasPairsId"
                              [disabled]="mode === 'normal'" name="hasPairs">
                  </p-checkbox>
                </div>
              </div>
              <label class="col-sm-2 control-label">
                NDI requis
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.has_ndi" [binary]="true" inputId="hasNdiId"
                              [disabled]="mode === 'normal'" name="hasNdI">
                  </p-checkbox>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Porte de collecte requise
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.has_collection_nodes" [binary]="true" inputId="hasCollectionNodesId"
                              [disabled]="mode === 'normal'" name="has_collection_nodes">
                  </p-checkbox>
                </div>
              </div>
              <label class="col-sm-2 control-label" *ngIf="detail.has_collection_nodes">
                Choix VLAN <i class="required">*</i>
              </label>
              <div class="col-sm-4" *ngIf="detail.has_collection_nodes">
                <select class="form-control wcm-highlight-req" [required]="detail.has_collection_nodes" [(ngModel)]="detail.vlan_provider"
                        [disabled]="mode === 'normal'" name="vlan_provider">
                  <option *ngFor="let opt of offerVlanOptions" [value]="opt.value">{{opt.label}}</option>
                </select>
              </div>
            </div>
            <div class="form-group" *ngIf="detail.has_collection_nodes && detail.vlan_provider=== 'waycom'">
              <div class="col-sm-6"></div>
              <label class="col-sm-2 control-label ">
                VLAN imposé
              </label>
              <div class="col-sm-4">
                <input class="form-control" name="vlan_imposed" type="number" min="1"
                       [readonly]="mode === 'normal'" [(ngModel)]="detail.vlan_imposed"
                       (ngModelChange)="checkNumber()" [ngClass]="{'wcm-highlight-error': detail.invalidNumber}"/>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Débit garanti requis
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.has_guaranteed_bps" [binary]="true" inputId="hasGuaranteedBpsId"
                              [disabled]="mode === 'normal'" name="has_guaranteed_bps">
                  </p-checkbox>
                </div>
              </div>
              <label class="col-sm-2 control-label">
                Débit max requis
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.has_max_bps" [binary]="true" inputId="hasMaxBpsId"
                              [disabled]="mode === 'normal'" name="has_max_bps">
                  </p-checkbox>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Délai de livraison (jours)
              </label>
              <div class="col-sm-4">
                <input modal class="form-control" [(ngModel)]="detail.delivery_days" name="delivery_days"
                       [readonly]="mode === 'normal'" type="number" min="0" />
              </div>
              <label class="col-sm-2 control-label">
                SLA (heures)
              </label>
              <div class="col-sm-4">
                <app-decimal-field class="wcm-highlight-req" required="true"
                                   [disabled]="mode === 'normal'" pattern="^\d*$" [maxDecimalPlaces]="0"
                                   [(ngModel)]="detail.contractual_sla" name="contractual_sla" >
                </app-decimal-field>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Format Prestation
              </label>
              <div class="col-sm-4">
                <input modal class="form-control" [(ngModel)]="detail.technical_ref_regexp" name="technical_ref_regexp" [readonly]="mode === 'normal'"/>
              </div>
              <label class="col-sm-2 control-label">
                Exemple Prestation
              </label>
              <div class="col-sm-4">
                <input modal class="form-control" [(ngModel)]="detail.example_technical_ref" name="example_technical_ref" [readonly]="mode === 'normal'"/>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Format Identification de facturation
              </label>
              <div class="col-sm-4">
                <input modal class="form-control" [(ngModel)]="detail.external_ref_regexp" name="external_ref_regexp" [readonly]="mode === 'normal'"/>
              </div>
              <label class="col-sm-2 control-label">
                Exemple Identification de facturation
              </label>
              <div class="col-sm-4">
                <input modal class="form-control" [(ngModel)]="detail.example_external_ref" name="example_external_ref" [readonly]="mode === 'normal'"/>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                Format réf. commande
              </label>
              <div class="col-sm-4">
                <input modal class="form-control" [(ngModel)]="detail.order_ref_regexp" name="order_ref_regexp" [readonly]="mode === 'normal'"/>
              </div>
              <label class="col-sm-2 control-label">
                Exemple réf. commande
              </label>
              <div class="col-sm-4">
                <input modal class="form-control" [(ngModel)]="detail.example_order_ref" name="example_order_ref" [readonly]="mode === 'normal'"/>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label" *ngIf="detail.type === 'dia'">
                Générateur login/pwd
              </label>
              <div class="col-sm-4">
                <div class="checkbox">
                  <p-checkbox [(ngModel)]="detail.generator_login_pwd" [binary]="true" inputId="genLoginPwd"
                              [disabled]="mode === 'normal'" name="genLoginPwd" (ngModelChange)="controlGeneratorAndSuffixPPP()"
                              *ngIf="detail.type === 'dia'">
                  </p-checkbox>
                </div>
              </div>
            </div>

          </ng-template>
        </li>

        <li [ngbNavItem]="3">
          <a ngbNavLink>Historique</a>
          <ng-template ngbNavContent>
            <app-model-histories-list *ngIf="tabsStatus[3]" [id]="detail.id" model="offer">
            </app-model-histories-list>
          </ng-template>
        </li>
      </ul>
      <!-- The tabs content is dislayed inside the outlet -->
      <div [ngbNavOutlet]="nav"></div>
    </ng-container> <!-- /tabs container -->
  </form>
</div>
