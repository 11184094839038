import { ISelectOption, IWorkOrderItemAdslLineOffer, IWorkOrderItemFOPRegion, IWorkOrderItemLineAction } from '@core/interfaces';

export type FormMode = 'normal' | 'edition';

export const DEPRECATED_EQUIPMENT_MODEL_TYPES = [
  'Disque dur',
  'Divers',
  'Terminaison RTC'
];

export const EQUIPMENT_MODEL_TYPES = [
  'Borne WiFi',
  'Carte SIM',
  'Convertisseur',
  'Dongle',
  'Firewall',
  'Imprimante',
  'Laptop',
  'Licences',
  'Optique',
  'Ordinateur fixe',
  'Poste mobile',
  'Routeur',
  'Switch',
  'TPE',
  'Téléphone DECT',
  'Téléphone fixe',
  'Visio'
];

export const EQUIPMENT_MODELS_CATEGORIES = {
  'null': 'Aucune',
  'network-device': 'Équipement réseau',
  'sim-card': 'Carte SIM',
  'meraki': 'Meraki',
  'manual-provi': 'Provi Manuel',
  'ip-phone': 'Téléphone IP',
  'dect-phone': 'Téléphone Dect',
  'consumable': 'Consommable & Accessoire'
};

export const PRODUCT_ACCOUNT_TYPE = {
  standard: 'Négoce',
  setup: 'Frais d\'activation',
  recurring: 'Frais récurrent',
  billing: 'Consommation variable',
  manual: 'Facturation libre'
};

export const EQUIPMENT_MODEL_CATEGORY_OPTIONS = [
  { value: 'network-device', label: 'Équipement réseau' },
  { value: 'sim-card', label: 'Carte SIM' },
  { value: 'meraki', label: 'Meraki' },
  { value: 'manual-provi', label: 'Provi Manuel' },
  { value: 'ip-phone', label: 'Téléphone IP' },
  { value: 'dect-phone', label: 'Téléphone Dect' },
  { value: 'consumable', label: 'Consommable & Accessoire' },
];

export const ENTITY_REPORT_CONFIGURATION_OPTIONS = [
  { value: 'ARGOS_PDF', label: 'Rapport Argos (PDF)'},
  { value: 'ARGOS_XLSX', label: 'Rapport Argos (Excel)'}
];

export const OFFER_TYPE_OPTIONS = {
  ppp: 'PPP',
  dia: 'DIA',
  interco: 'Interco',
  l2_ptp: 'L2 point-to-point'
};

export const OFFER_TYPE_OPTIONS_DICT = [
  { value: 'ppp', label: 'PPP' },
  { value: 'dia', label: 'DIA' },
  { value: 'interco', label: 'Interco' },
  { value: 'l2_ptp', label: 'L2 point-to-point' }
];

export const CONTACT_FUNCTIONS = [
  {key: 'buyer', value: 'Acheteur'},
  {key: 'other', value: 'Autre'},
  {key: 'project_manager', value: 'Chef de projet'},
  {key: 'billing', value: 'Comptabilité'},
  {key: 'cfo', value: 'DAF'},
  {key: 'cio', value: 'DSI'},
  {key: 'ce', value: 'Directeur général'},
  {key: 'cto', value: 'Directeur technique'},
  {key: 'decision_maker', value: 'Décideur'},
  {key: 'shipping', value: 'Livraison'},
  {key: 'telecom_delivery', value: 'Livraison telecom'},
  {key: 'maintenance', value: 'Maintenance'},
  {key: 'ceo', value: 'PDG'},
  {key: 'prescriber', value: 'Prescripteur'},
  {key: 'recovery', value: 'Recouvrement'},
  {key: 'region_manager', value: 'Responsable région'},
  {key: 'site_manager', value: 'Responsable site'}
];

export const CONTACT_FUNCTIONS_ENUM = {
  buyer: 'Acheteur',
  other: 'Autre',
  project_manager: 'Chef de projet',
  billing: 'Comptabilité',
  cfo: 'DAF',
  cio: 'DSI',
  ce: 'Directeur général',
  cto: 'Directeur technique',
  decision_maker: 'Décideur',
  shipping: 'Livraison',
  telecom_delivery: 'Livraison telecom',
  maintenance: 'Maintenance',
  ceo: 'PDG',
  prescriber: 'Prescripteur',
  recovery: 'Recouvrement',
  region_manager: 'Responsable région',
  site_manager: 'Responsable site'
};

export enum EquipmentLocationEnum {
  Collect = 'COLLECT',
  Stock = 'STOCK',
  Shop = 'SHOP',
  Trash = 'TRASH',
  Pending = 'PENDING',
  Invoicing = 'INVOICING',
}

export const EQP_LOCATIONS = {
  [EquipmentLocationEnum.Collect]: 'Prélèvement',
  [EquipmentLocationEnum.Stock]: 'Stock',
  [EquipmentLocationEnum.Shop]: 'Magasin',
  [EquipmentLocationEnum.Trash]: 'Poubelle',
  [EquipmentLocationEnum.Pending]: 'En attente',
  [EquipmentLocationEnum.Invoicing]: 'Facturé',
};

export const EQP_OWNERS = {
  adista: 'Adista',
  cyberprotect: 'CyberProtect',
  retail: 'Waycom Retail',
  group: 'Waycom Group',
  international: 'Waycom International',
  integration: 'Waycom Integration',
  unyc: 'Unyc',
  deploiement: 'Waycom Déploiement',
  other: 'Autre'
};

export const EQP_OWNERS_ORDERED_OPTIONS = [
  {key: 'adista', value: 'Adista'},
  {key: 'cyberprotect', value: 'CyberProtect'},
  {key: 'deploiement', value: 'Waycom Déploiement'},
  {key: 'group', value: 'Waycom Group'},
  {key: 'integration', value: 'Waycom Integration'},
  {key: 'international', value: 'Waycom International'},
  {key: 'retail', value: 'Waycom Retail'},
  {key: 'unyc', value: 'Unyc'},
  {key: 'other', value: 'Autre'}
];

export const CONFIG_TEMPLATE_TYPES = {
  router: 'Routeur',
  l2switch: 'Switch L2',
  wifi: 'Wifi',
  firewall: 'Firewall'
};

export const MERAKI_CONFIG_TEMPLATE_TYPES = {
  meraki_mr: 'Meraki MR',
  meraki_ms: 'Meraki MS',
  meraki_mx: 'Meraki MX',
  meraki_mg: 'Meraki MG',
};

export const PAYABLE_OPTIONS = [
  { label: 'Terme à échoir', value: 0 },
  { label: 'Terme échu', value: 1 }
];

export interface IPrinterOption {
  default: boolean;
  value: string;
  label: string;
}

export const PRINTER_OPTIONS: IPrinterOption[] = [
  { default: false, value: 'bordeaux01', label: 'Bordeaux 1' },
  { default: false, value: 'maxeville01', label: 'Maxeville 1' },
  { default: true, value: 'nanterre01', label: 'Nanterre 1' },
  { default: false, value: 'labege01', label: 'Labege 1' },
  { default: false, value: 'test01', label: 'Test 1' }
];

export const PRODUCT_PARAM_TYPES = {
  KIND_MULTIPLE: {name: 'Valeurs multiples', value: 'KIND_MULTIPLE'},
  KIND_SINGLE: {name: 'Champ libre', value: 'KIND_SINGLE'}
};

export const WOI_ADSL_LINE_ACTIONS: IWorkOrderItemLineAction[] = [
  {
    value: 'CREATION',
    label: 'Création',
    ol_states: ['new']
  },
  {
    value: 'MODIF',
    label: 'Modification offre',
    ol_states: ['delivered']
  },
  {
    value: 'MODIF_TIT',
    label: 'Modification titulaire',
    ol_states: ['delivered']
  },
  {
    value: 'MODIF_SAV',
    label: 'Modification SAV',
    ol_states: ['delivered']
  },
  {
    value: 'RESIL',
    label: 'Résiliation',
    ol_states: ['delivered', 'terminable']
  },
  {
    value: 'ANNUL',
    label: 'Annulation',
    ol_states: [],
  },
];

export const WOI_ADSL_LINE_OFFERS: IWorkOrderItemAdslLineOffer[] = [
  // acces_essentiel
  {
    profile: 'PROFIL_39', technology: 'ADSL', porta: 0, rtc: 0, bivc: 0,
    id: 1, label: 'Accès Essentiel : ADSL / sans RTC',
    group: 'Accès Essentiel', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_39', technology: 'ADSL', porta: 1, rtc: 0, bivc: 0,
    id: 2, label: 'Accès Essentiel : ADSL / sans RTC / porta',
    group: 'Accès Essentiel', erdvAvailable: false, natureProduit: null
  },

  // access_50_megamax
  {
    profile: 'PROFIL_16', technology: 'VDSL', porta: 0, rtc: 1, bivc: 0,
    id: 3, label: 'DSL Access 50 MégaMax : VDSL / RTC',
    group: 'DSL Access 50 MégaMax', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_16', technology: 'VDSL', porta: 0, rtc: 0, bivc: 0,
    id: 4, label: 'DSL Access 50 MégaMax : VDSL / sans RTC',
    group: 'DSL Access 50 MégaMax', erdvAvailable: true, natureProduit: 'VNU'
  },
  {
    profile: 'PROFIL_16', technology: 'VDSL', porta: 1, rtc: 0, bivc: 0,
    id: 5, label: 'DSL Access 50 MégaMax : VDSL / sans RTC / porta',
    group: 'DSL Access 50 MégaMax', erdvAvailable: true, natureProduit: 'VNU'
  },
  {
    profile: 'PROFIL_31', technology: 'VDSL', porta: 0, rtc: 1, bivc: 1,
    id: 6, label: 'DSL Access 50 MégaMax : VDSL / RTC / bi-VC',
    group: 'DSL Access 50 MégaMax', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_31', technology: 'VDSL', porta: 0, rtc: 0, bivc: 1,
    id: 7, label: 'DSL Access 50 MégaMax : VDSL / sans RTC / bi-VC',
    group: 'DSL Access 50 MégaMax', erdvAvailable: true, natureProduit: 'VNU'
  },
  {
    profile: 'PROFIL_31', technology: 'VDSL', porta: 1, rtc: 0, bivc: 1,
    id: 8, label: 'DSL Access 50 MégaMax : VDSL / sans RTC / porta / bi-VC',
    group: 'DSL Access 50 MégaMax', erdvAvailable: true, natureProduit: 'VNU'
  },

  // access_2_megamax
  {
    profile: 'PROFIL_15', technology: 'ADSL', porta: 0, rtc: 1, bivc: 0,
    id: 9, label: 'DSL Access 2 MégaMax : ADSL / RTC',
    group: 'DSL Access 2 MégaMax'
  },
  {
    profile: 'PROFIL_15', technology: 'ADSL', porta: 0, rtc: 0, bivc: 0,
    id: 10, label: 'DSL Access 2 MégaMax : ADSL / sans RTC',
    group: 'DSL Access 2 MégaMax', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_15', technology: 'ADSL', porta: 1, rtc: 0, bivc: 0,
    id: 11, label: 'DSL Access 2 MégaMax : ADSL / sans RTC / porta',
    group: 'DSL Access 2 MégaMax', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_30', technology: 'ADSL', porta: 0, rtc: 1, bivc: 1,
    id: 12, label: 'DSL Access 2 MégaMax : ADSL / RTC / bi-VC',
    group: 'DSL Access 2 MégaMax', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_30', technology: 'ADSL', porta: 0, rtc: 0, bivc: 1,
    id: 13, label: 'DSL Access 2 MégaMax : ADSL / sans RTC / bi-VC',
    group: 'DSL Access 2 MégaMax', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_30', technology: 'ADSL', porta: 1, rtc: 0, bivc: 1,
    id: 14, label: 'DSL Access 2 MégaMax : ADSL / sans RTC / porta / bi-VC',
    group: 'DSL Access 2 MégaMax', erdvAvailable: true, natureProduit: 'ADSL'
  },

  // access_1_megamax
  {
    profile: 'PROFIL_14', technology: 'ADSL', porta: 0, rtc: 1, bivc: 0,
    id: 15, label: 'DSL Access 1 MégaMax : ADSL / RTC',
    group: 'DSL Access 1 MégaMax', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_14', technology: 'ADSL', porta: 0, rtc: 0, bivc: 0,
    id: 16, label: 'DSL Access 1 MégaMax : ADSL / sans RTC',
    group: 'DSL Access 1 MégaMax', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_14', technology: 'ADSL', porta: 1, rtc: 0, bivc: 0,
    id: 17, label: 'DSL Access 1 MégaMax : ADSL / sans RTC / porta',
    group: 'DSL Access 1 MégaMax', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_24', technology: 'ADSL', porta: 0, rtc: 1, bivc: 1,
    id: 18, label: 'DSL Access 1 MégaMax : ADSL / RTC / bi-VC',
    group: 'DSL Access 1 MégaMax', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_24', technology: 'ADSL', porta: 0, rtc: 0, bivc: 1,
    id: 19, label: 'DSL Access 1 MégaMax : ADSL / sans RTC / bi-VC',
    group: 'DSL Access 1 MégaMax', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_24', technology: 'ADSL', porta: 1, rtc: 0, bivc: 1,
    id: 20, label: 'DSL Access 1 MégaMax : ADSL / sans RTC / porta / bi-VC',
    group: 'DSL Access 1 MégaMax', erdvAvailable: true, natureProduit: 'ADSL'
  },

  // access_max2
  {
    profile: 'PROFIL_11', technology: 'THD', porta: 0, rtc: 1, bivc: 0,
    id: 21, label: 'DSL Access Max2 : THD / RTC',
    group: 'DSL Access Max2', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_11', technology: 'THD', porta: 0, rtc: 0, bivc: 0,
    id: 22, label: 'DSL Access Max2 : THD / sans RTC',
    group: 'DSL Access Max2', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_11', technology: 'THD', porta: 1, rtc: 0, bivc: 0,
    id: 23, label: 'DSL Access Max2 : THD / sans RTC / porta',
    group: 'DSL Access Max2', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_23', technology: 'THD', porta: 0, rtc: 1, bivc: 1,
    id: 24, label: 'DSL Access Max2 : THD / RTC / bi-VC',
    group: 'DSL Access Max2', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_23', technology: 'THD', porta: 0, rtc: 0, bivc: 1,
    id: 25, label: 'DSL Access Max2 : THD / sans RTC / bi-VC',
    group: 'DSL Access Max2', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_23', technology: 'THD', porta: 1, rtc: 0, bivc: 1,
    id: 26, label: 'DSL Access Max2 : THD / sans RTC / porta / bi-VC',
    group: 'DSL Access Max2', erdvAvailable: true, natureProduit: 'ADSL'
  },

  // access_max
  {
    profile: 'PROFIL_6', technology: 'ADSL', porta: 0, rtc: 1, bivc: 0,
    id: 27, label: 'DSL Access Max : ADSL / RTC',
    group: 'DSL Access Max', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_6', technology: 'ADSL', porta: 0, rtc: 0, bivc: 0,
    id: 28, label: 'DSL Access Max : ADSL / sans RTC',
    group: 'DSL Access Max', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_6', technology: 'ADSL', porta: 1, rtc: 0, bivc: 0,
    id: 29, label: 'DSL Access Max : ADSL / sans RTC / porta',
    group: 'DSL Access Max', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_22', technology: 'ADSL', porta: 0, rtc: 1, bivc: 1,
    id: 30, label: 'DSL Access Max : ADSL / RTC / bi-VC',
    group: 'DSL Access Max', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_22', technology: 'ADSL', porta: 0, rtc: 0, bivc: 1,
    id: 31, label: 'DSL Access Max : ADSL / sans RTC / bi-VC',
    group: 'DSL Access Max', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_22', technology: 'ADSL', porta: 1, rtc: 0, bivc: 1,
    id: 32, label: 'DSL Access Max : ADSL / sans RTC / porta / bi-VC',
    group: 'DSL Access Max', erdvAvailable: true, natureProduit: 'ADSL'
  },

  // access_2432_320
  {
    profile: 'PROFIL_5', technology: 'ADSL', porta: 0, rtc: 1, bivc: 0,
    id: 33, label: 'DSL Access 2432/320 : ADSL / RTC',
    group: 'DSL Access 2432/320', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_5', technology: 'ADSL', porta: 0, rtc: 0, bivc: 0,
    id: 34, label: 'DSL Access 2432/320 : ADSL / sans RTC',
    group: 'DSL Access 2432/320', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_5', technology: 'ADSL', porta: 1, rtc: 0, bivc: 0,
    id: 35, label: 'DSL Access 2432/320 : ADSL / sans RTC / porta',
    group: 'DSL Access 2432/320', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_29', technology: 'ADSL', porta: 0, rtc: 1, bivc: 1,
    id: 36, label: 'DSL Access 2432/320 : ADSL / RTC / bi-VC',
    group: 'DSL Access 2432/320', erdvAvailable: false, natureProduit: null
  },

  // access_2432_160
  {
    profile: 'PROFIL_4', technology: 'ADSL', porta: 0, rtc: 1, bivc: 0,
    id: 37, label: 'DSL Access 2432/160 : ADSL / RTC',
    group: 'DSL Access 2432/160', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_4', technology: 'ADSL', porta: 0, rtc: 0, bivc: 0,
    id: 38, label: 'DSL Access 2432/160 : ADSL / sans RTC',
    group: 'DSL Access 2432/160', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_4', technology: 'ADSL', porta: 1, rtc: 0, bivc: 0,
    id: 39, label: 'DSL Access 2432/160 : ADSL / sans RTC / porta',
    group: 'DSL Access 2432/160', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_28', technology: 'ADSL', porta: 0, rtc: 1, bivc: 1,
    id: 40, label: 'DSL Access 2432/160 : ADSL / RTC / bi-VC',
    group: 'DSL Access 2432/160', erdvAvailable: false, natureProduit: null
  },

  // access_1216_160
  {
    profile: 'PROFIL_3', technology: 'ADSL', porta: 0, rtc: 1, bivc: 0,
    id: 41, label: 'DSL Access 1216/160 : ADSL / RTC',
    group: 'DSL Access 1216/160', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_3', technology: 'ADSL', porta: 0, rtc: 0, bivc: 0,
    id: 42, label: 'DSL Access 1216/160 : ADSL / sans RTC',
    group: 'DSL Access 1216/160', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_3', technology: 'ADSL', porta: 1, rtc: 0, bivc: 0,
    id: 43, label: 'DSL Access 1216/160 : ADSL / sans RTC / porta',
    group: 'DSL Access 1216/160', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_26', technology: 'ADSL', porta: 0, rtc: 1, bivc: 1,
    id: 44, label: 'DSL Access 1216/160 : ADSL / RTC / bi-VC',
    group: 'DSL Access 1216/160', erdvAvailable: false, natureProduit: null
  },

  // access_608_160
  {
    profile: 'PROFIL_1', technology: 'ADSL', porta: 0, rtc: 1, bivc: 0,
    id: 45, label: 'DSL Access 608/160 : ADSL / RTC',
    group: 'DSL Access 608/160', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_1', technology: 'ADSL', porta: 0, rtc: 0, bivc: 0,
    id: 46, label: 'DSL Access 608/160 : ADSL / sans RTC',
    group: 'DSL Access 608/160', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_1', technology: 'ADSL', porta: 1, rtc: 0, bivc: 0,
    id: 47, label: 'DSL Access 608/160 : ADSL / sans RTC / porta',
    group: 'DSL Access 608/160', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_25', technology: 'ADSL', porta: 0, rtc: 1, bivc: 1,
    id: 48, label: 'DSL Access 608/160 : ADSL / RTC / bi-VC',
    group: 'DSL Access 608/160', erdvAvailable: false, natureProduit: null
  },

  // access_608_160 RE
  {
    profile: 'PROFIL_1', technology: 'RE', porta: 0, rtc: 1, bivc: 0,
    id: 49, label: 'DSL Access 608/160 : RE / RTC',
    group: 'DSL Access 608/160 RE', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_1', technology: 'RE', porta: 0, rtc: 0, bivc: 0,
    id: 50, label: 'DSL Access 608/160 : RE / sans RTC',
    group: 'DSL Access 608/160 RE', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_1', technology: 'RE', porta: 1, rtc: 0, bivc: 0,
    id: 51, label: 'DSL Access 608/160 : RE / sans RTC / porta',
    group: 'DSL Access 608/160 RE', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_25', technology: 'RE', porta: 0, rtc: 1, bivc: 1,
    id: 52, label: 'DSL Access 608/160 : RE / RTC / bi-VC',
    group: 'DSL Access 608/160 RE', erdvAvailable: false, natureProduit: null
  },
  {
    profile: 'PROFIL_25', technology: 'RE', porta: 0, rtc: 0, bivc: 1,
    id: 53, label: 'DSL Access 608/160 : RE / sans RTC / bi-VC',
    group: 'DSL Access 608/160 RE', erdvAvailable: true, natureProduit: 'ADSL'
  },
  {
    profile: 'PROFIL_25', technology: 'RE', porta: 1, rtc: 0, bivc: 1,
    id: 54, label: 'DSL Access 608/160 : RE / sans RTC / porta / bi-VC',
    group: 'DSL Access 608/160 RE', erdvAvailable: true, natureProduit: 'ADSL'
  },

  // access_160_96
  {
    profile: 'PROFIL_0', technology: 'ADSL', porta: 0, rtc: 1, bivc: 0,
    id: 55, label: 'DSL Access 160/96 : ADSL / RTC',
    group: 'DSL Access 160/96', erdvAvailable: false, natureProduit: null
  },
];

export const WOI_SDSL_LINE_ACTIONS = {
  C2E: [
    {
      label: 'Création',
      value: 'C',
      ol_states: ['new']
    },
    {
      label: 'Ajout Cession Ligne Active',
      value: 'ACLA',
      ol_states: ['delivered']
    },
    {
      label: 'Ajout option de sécurisation',
      value: 'MO',
      ol_states: ['delivered']
    },
    {
      label: 'Changement de débit ou services',
      value: 'MSO',
      ol_states: ['delivered']
    },
    // not implemented
    // {
    //   label: 'Transfert d'extrémité',
    //   value: 'MTE'
    // },
    // {
    //   label: 'Changement d'aire VPLS au titre du changement de titulaire',
    //   value: 'MVT'
    // },
    {
      label: 'Résiliation',
      value: 'R',
      ol_states: ['delivered', 'terminable']
    }
  ],
  C2E_CN2: [
    {
      label: 'Création',
      value: 'C',
      ol_states: ['new']
    },
    {
      label: 'Changement de services',
      value: 'MO',
      ol_states: ['delivered']
    },
    // not implemented
    // {
    //   label: 'Transfert d'extrémité',
    //   value: 'MTE'
    // },
    // {
    //   label: 'Changement d'aire VPLS au titre du changement de titulaire',
    //   value: 'MVT'
    // },
    {
      label: 'Résiliation',
      value: 'R',
      ol_states: ['delivered', 'terminable']
    }
  ],
  DSLE: [
    {
      label: 'Création',
      value: 'C',
      ol_states: ['new']
    },
    // not implemented
    // {
    //   label: 'Migration vers un autre Raccordement',
    //   value: 'MM'
    // },
    // {
    //   label: 'Migration vers un autre Raccordement avec changement de titulaire',
    //   value: 'MRT'
    // },
    {
      label: 'Changement de débit ou services',
      value: 'MSO',
      ol_states: ['delivered']
    },
    // not implemented
    // {
    //   label: 'Transfert d'extrémité',
    //   value: 'MTE'
    // },
    {
      label: 'Résiliation',
      value: 'R',
      ol_states: ['delivered', 'terminable']
    }
  ],
};

export const WOI_SDSL_LINE_OFFERS = {
  C2E: [
    {
      ref: 'sdsl_1p_0,5_mbit_s',
      label: 'Debit : 0,5 Mbps, Paires : 1',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_2p_0,5_mbit_s',
      label: 'Debit : 0,5 Mbps, Paires : 2',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_1p_1_mbit_s',
      label: 'Debit : 1 Mbps, Paires : 1',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_2p_1_mbit_s',
      label: 'Debit : 1 Mbps, Paires : 2',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_4p_1_mbit_s',
      label: 'Debit : 1 Mbps, Paires : 4',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_1p_2_mbit_s',
      label: 'Debit : 2 Mbps, Paires : 1',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_2p_2_mbit_s',
      label: 'Debit : 2 Mbps, Paires : 2',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_4p_2_mbit_s',
      label: 'Debit : 2 Mbps, Paires : 4',
      technology: 'C2E'
    },
    {
      ref: 'sdslbis_1p_4_mbit_s',
      label: 'Debit : 4 Mbps, Paires : 1',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_2p_4_mbit_s',
      label: 'Debit : 4 Mbps, Paires : 2',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_4p_4_mbit_s',
      label: 'Debit : 4 Mbps, Paires : 4',
      technology: 'C2E'
    },
    {
      ref: 'sdslbis_2p_8_mbit_s',
      label: 'Debit : 8 Mbps, Paires : 2',
      technology: 'C2E'
    },
    {
      ref: 'sdsl_4p_8_mbit_s',
      label: 'Debit : 8 Mbps, Paires : 4',
      technology: 'C2E'
    },
    {
      ref: 'sdslbis_4p_12_mbit_s',
      label: 'Debit : 12 Mbps, Paires : 4',
      technology: 'C2E'
    },
    {
      ref: 'sdslbis_4p_16_mbit_s',
      label: 'Debit : 16 Mbps, Paires : 4',
      technology: 'C2E'
    },
    {
      ref: '',
      label: '(Inchangé)',
      technology: 'C2E'
    }
  ],
  DSLE: [
    {
      ref: 'SDSL1P75K0,5',
      label: 'Paires : 1, Debit Garanti : 75 Kbps, Debit Max : 0,5 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL1P75K1',
      label: 'Paires : 1, Debit Garanti : 75 Kbps, Debit Max : 1 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL1P75K2',
      label: 'Paires : 1, Debit Garanti : 75 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL1P150K1',
      label: 'Paires : 1, Debit Garanti : 150 Kbps, Debit Max : 1 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL1P150K2',
      label: 'Paires : 1, Debit Garanti : 150 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL1P250K2,3',
      label: 'Paires : 1, Debit Garanti : 250 Kbps, Debit Max : 2,3 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL1P500K0,5',
      label: 'Paires : 1, Debit Garanti : 500 Kbps, Debit Max : 0,5 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL1P500K2',
      label: 'Paires : 1, Debit Garanti : 500 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P75K0,5',
      label: 'Paires : 2, Debit Garanti : 75 Kbps, Debit Max : 0,5 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P75K1',
      label: 'Paires : 2, Debit Garanti : 75 Kbps, Debit Max : 1 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P75K2',
      label: 'Paires : 2, Debit Garanti : 75 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P150K1',
      label: 'Paires : 2, Debit Garanti : 150 Kbps, Debit Max : 1 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P150K2',
      label: 'Paires : 2, Debit Garanti : 150 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P250K2,3',
      label: 'Paires : 2, Debit Garanti : 250 Kbps, Debit Max : 2,3 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P500K0,5',
      label: 'Paires : 2, Debit Garanti : 500 Kbps, Debit Max : 0,5 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P500K2',
      label: 'Paires : 2, Debit Garanti : 500 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P500K4',
      label: 'Paires : 2, Debit Garanti : 500 Kbps, Debit Max : 4 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P500K4,6',
      label: 'Paires : 2, Debit Garanti : 500 Kbps, Debit Max : 4,6 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P1000K1',
      label: 'Paires : 2, Debit Garanti : 1000 Kbps, Debit Max : 1 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P1000K4',
      label: 'Paires : 2, Debit Garanti : 1000 Kbps, Debit Max : 4 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P2000K2',
      label: 'Paires : 2, Debit Garanti : 2000 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P2000K4',
      label: 'Paires : 2, Debit Garanti : 2000 Kbps, Debit Max : 4 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P2300K2,3',
      label: 'Paires : 2, Debit Garanti : 2300 Kbps, Debit Max : 2,3 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL2P4600K4,6',
      label: 'Paires : 2, Debit Garanti : 4600 Kbps, Debit Max : 4,6 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P150K1',
      label: 'Paires : 4, Debit Garanti : 150 Kbps, Debit Max : 1 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P150K2',
      label: 'Paires : 4, Debit Garanti : 150 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P250K2,3',
      label: 'Paires : 4, Debit Garanti : 250 Kbps, Debit Max : 2,3 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P500K2',
      label: 'Paires : 4, Debit Garanti : 500 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P500K4',
      label: 'Paires : 4, Debit Garanti : 500 Kbps, Debit Max : 4 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P500K4,6',
      label: 'Paires : 4, Debit Garanti : 500 Kbps, Debit Max : 4,6 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P1000K1',
      label: 'Paires : 4, Debit Garanti : 1000 Kbps, Debit Max : 1 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P1000K4',
      label: 'Paires : 4, Debit Garanti : 1000 Kbps, Debit Max : 4 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P1000K8',
      label: 'Paires : 4, Debit Garanti : 1000 Kbps, Debit Max : 8 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P2000K2',
      label: 'Paires : 4, Debit Garanti : 2000 Kbps, Debit Max : 2 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P2000K4',
      label: 'Paires : 4, Debit Garanti : 2000 Kbps, Debit Max : 4 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P2000K8',
      label: 'Paires : 4, Debit Garanti : 2000 Kbps, Debit Max : 8 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P2300K2,3',
      label: 'Paires : 4, Debit Garanti : 2300 Kbps, Debit Max : 2,3 Mbps',
      technology: 'DSLE'
    },
    {
      ref: 'SDSL4P4600K4,6',
      label: 'Paires : 4, Debit Garanti : 4600 Kbps, Debit Max : 4,6 Mbps',
      technology: 'DSLE'
    },
    {
      ref: '',
      label: '(Inchangé)',
      technology: 'DSLE'
    }
  ]
};

export const WOI_FOP_REGIONS: Record<string, IWorkOrderItemFOPRegion> = {
  '01': {label: 'VV998922 - Rhône (01, 07, 26, 42, 69)', value: 'VV998922'},
  '02': {label: 'VV998916 - Picardie (02, 60, 80)', value: 'VV998916'},
  '03': {label: 'VV998884 - Auvergne (03, 15, 43, 63)', value: 'VV998884'},
  '04': {label: 'VV998918 - Provence (04, 05, 13, 20 (2A/2B), 84)', value: 'VV998918'},
  '05': {label: 'VV998918 - Provence (04, 05, 13, 20 (2A/2B), 84)', value: 'VV998918'},
  '06': {label: 'VV998869 - Alpes Maritimes (6)', value: 'VV998869'},
  '07': {label: 'VV998922 - Rhône (01, 07, 26, 42, 69)', value: 'VV998922'},
  '08': {label: 'VV998899 - Champagne Ardennes (08, 10, 51, 52)', value: 'VV998899'},
  '09': {label: 'VV998910 - Midi Pyrénées (09, 12, 31, 32, 46, 65, 81, 82)', value: 'VV998910'},
  '10': {label: 'VV998899 - Champagne Ardennes (08, 10, 51, 52)', value: 'VV998899'},
  '11': {label: 'VV998904 - Languedoc Roussillon (11, 30, 34, 48, 66)', value: 'VV998904'},
  '12': {label: 'VV998910 - Midi Pyrénées (09, 12, 31, 32, 46, 65, 81, 82)', value: 'VV998910'},
  '13': {label: 'VV998918 - Provence (04, 05, 13, 20 (2A/2B), 84)', value: 'VV998918'},
  '14': {label: 'VV998887 - Basse Normandie (14, 50, 61)', value: 'VV998887'},
  '15': {label: 'VV998884 - Auvergne (03, 15, 43, 63)', value: 'VV998884'},
  '16': {label: 'VV998906 - Limousin Poitou Charentes (16, 17, 19, 23, 79, 86, 87)', value: 'VV998906'},
  '17': {label: 'VV998906 - Limousin Poitou Charentes (16, 17, 19, 23, 79, 86, 87)', value: 'VV998906'},
  '18': {label: 'VV998894 - Centre (18, 28, 36, 37, 41, 45)', value: 'VV998894'},
  '19': {label: 'VV998906 - Limousin Poitou Charentes (16, 17, 19, 23, 79, 86, 87)', value: 'VV998906'},
  '2A': {label: 'VV998918 - Provence (04, 05, 13, 20 (2A/2B), 84)', value: 'VV998918'},
  '2B': {label: 'VV998918 - Provence (04, 05, 13, 20 (2A/2B), 84)', value: 'VV998918'},
  '20': {label: 'VV998918 - Provence (04, 05, 13, 20 (2A/2B), 84)', value: 'VV998918'},
  '21': {label: 'VV998890 - Bourgogne / Franche Comté (21, 25, 39, 58, 70, 71, 89, 90)', value: 'VV998890'},
  '22': {label: 'VV998892 - Bretagne (22, 29, 35, 56)', value: 'VV998892'},
  '23': {label: 'VV998906 - Limousin Poitou Charentes (16, 17, 19, 23, 79, 86, 87)', value: 'VV998906'},
  '24': {label: 'VV998880 - Aquitaine (24, 33, 47)', value: 'VV998880'},
  '25': {label: 'VV998890 - Bourgogne / Franche Comté (21, 25, 39, 58, 70, 71, 89, 90)', value: 'VV998890'},
  '26': {label: 'VV998922 - Rhône (01, 07, 26, 42, 69)', value: 'VV998922'},
  '27': {label: 'VV998901 - Haute Normandie (27, 76)', value: 'VV998901'},
  '28': {label: 'VV998894 - Centre (18, 28, 36, 37, 41, 45)', value: 'VV998894'},
  '29': {label: 'VV998892 - Bretagne (22, 29, 35, 56)', value: 'VV998892'},
  '30': {label: 'VV998904 - Languedoc Roussillon (11, 30, 34, 48, 66)', value: 'VV998904'},
  '31': {label: 'VV998910 - Midi Pyrénées (09, 12, 31, 32, 46, 65, 81, 82)', value: 'VV998910'},
  '32': {label: 'VV998910 - Midi Pyrénées (09, 12, 31, 32, 46, 65, 81, 82)', value: 'VV998910'},
  '33': {label: 'VV998880 - Aquitaine (24, 33, 47)', value: 'VV998880'},
  '34': {label: 'VV998904 - Languedoc Roussillon (11, 30, 34, 48, 66)', value: 'VV998904'},
  '35': {label: 'VV998892 - Bretagne (22, 29, 35, 56)', value: 'VV998892'},
  '36': {label: 'VV998894 - Centre (18, 28, 36, 37, 41, 45)', value: 'VV998894'},
  '37': {label: 'VV998894 - Centre (18, 28, 36, 37, 41, 45)', value: 'VV998894'},
  '38': {label: 'VV998875 - Alpes Sud (38, 73)', value: 'VV998875'},
  '39': {label: 'VV998890 - Bourgogne / Franche Comté (21, 25, 39, 58, 70, 71, 89, 90)', value: 'VV998890'},
  '40': {label: 'VV998920 - Pyrénées Atlantique (40, 64)', value: 'VV998920'},
  '41': {label: 'VV998894 - Centre (18, 28, 36, 37, 41, 45)', value: 'VV998894'},
  '42': {label: 'VV998922 - Rhône (01, 07, 26, 42, 69)', value: 'VV998922'},
  '43': {label: 'VV998884 - Auvergne (03, 15, 43, 63)', value: 'VV998884'},
  '44': {label: 'VV998914 - Pays de Loire (44, 49, 53, 72, 85)', value: 'VV998914'},
  '45': {label: 'VV998894 - Centre (18, 28, 36, 37, 41, 45)', value: 'VV998894'},
  '46': {label: 'VV998910 - Midi Pyrénées (09, 12, 31, 32, 46, 65, 81, 82)', value: 'VV998910'},
  '47': {label: 'VV998880 - Aquitaine (24, 33, 47)', value: 'VV998880'},
  '48': {label: 'VV998904 - Languedoc Roussillon (11, 30, 34, 48, 66)', value: 'VV998904'},
  '49': {label: 'VV998914 - Pays de Loire (44, 49, 53, 72, 85)', value: 'VV998914'},
  '50': {label: 'VV998887 - Basse Normandie (14, 50, 61)', value: 'VV998887'},
  '51': {label: 'VV998899 - Champagne Ardennes (08, 10, 51, 52)', value: 'VV998899'},
  '52': {label: 'VV998899 - Champagne Ardennes (08, 10, 51, 52)', value: 'VV998899'},
  '53': {label: 'VV998914 - Pays de Loire (44, 49, 53, 72, 85)', value: 'VV998914'},
  '54': {label: 'VV998908 - Lorraine (54, 55, 57, 88)', value: 'VV998908'},
  '55': {label: 'VV998908 - Lorraine (54, 55, 57, 88)', value: 'VV998908'},
  '56': {label: 'VV998892 - Bretagne (22, 29, 35, 56)', value: 'VV998892'},
  '57': {label: 'VV998908 - Lorraine (54, 55, 57, 88)', value: 'VV998908'},
  '58': {label: 'VV998890 - Bourgogne / Franche Comté (21, 25, 39, 58, 70, 71, 89, 90)', value: 'VV998890'},
  '59': {label: 'VV998912 - Nord Pas-de-Calais (59, 62)', value: 'VV998912'},
  '60': {label: 'VV998916 - Picardie (02, 60, 80)', value: 'VV998916'},
  '61': {label: 'VV998887 - Basse Normandie (14, 50, 61)', value: 'VV998887'},
  '62': {label: 'VV998912 - Nord Pas-de-Calais (59, 62)', value: 'VV998912'},
  '63': {label: 'VV998884 - Auvergne (03, 15, 43, 63)', value: 'VV998884'},
  '64': {label: 'VV998920 - Pyrénées Atlantique (40, 64)', value: 'VV998920'},
  '65': {label: 'VV998910 - Midi Pyrénées (09, 12, 31, 32, 46, 65, 81, 82)', value: 'VV998910'},
  '66': {label: 'VV998904 - Languedoc Roussillon (11, 30, 34, 48, 66)', value: 'VV998904'},
  '67': {label: 'VV998878 - Alsace (67, 68)', value: 'VV998878'},
  '68': {label: 'VV998878 - Alsace (67, 68)', value: 'VV998878'},
  '69': {label: 'VV998922 - Rhône (01, 07, 26, 42, 69)', value: 'VV998922'},
  '70': {label: 'VV998890 - Bourgogne / Franche Comté (21, 25, 39, 58, 70, 71, 89, 90)', value: 'VV998890'},
  '71': {label: 'VV998890 - Bourgogne / Franche Comté (21, 25, 39, 58, 70, 71, 89, 90)', value: 'VV998890'},
  '72': {label: 'VV998914 - Pays de Loire (44, 49, 53, 72, 85)', value: 'VV998914'},
  '73': {label: 'VV998875 - Alpes Sud (38, 73)', value: 'VV998875'},
  '74': {label: 'VV998872 - Alpes Nord (74)', value: 'VV998872'},
  '75': {label: 'VV998864 - Île-de-France (75, 77, 78, 91, 92, 93, 94, 95)', value: 'VV998864'},
  '76': {label: 'VV998901 - Haute Normandie (27, 76)', value: 'VV998901'},
  '77': {label: 'VV998864 - Île-de-France (75, 77, 78, 91, 92, 93, 94, 95)', value: 'VV998864'},
  '78': {label: 'VV998864 - Île-de-France (75, 77, 78, 91, 92, 93, 94, 95)', value: 'VV998864'},
  '79': {label: 'VV998906 - Limousin Poitou Charentes (16, 17, 19, 23, 79, 86, 87)', value: 'VV998906'},
  '80': {label: 'VV998916 - Picardie (02, 60, 80)', value: 'VV998916'},
  '81': {label: 'VV998910 - Midi Pyrénées (09, 12, 31, 32, 46, 65, 81, 82)', value: 'VV998910'},
  '82': {label: 'VV998910 - Midi Pyrénées (09, 12, 31, 32, 46, 65, 81, 82)', value: 'VV998910'},
  '83': {label: 'VV998924 - Var (83)', value: 'VV998924'},
  '84': {label: 'VV998918 - Provence (04, 05, 13, 20 (2A/2B), 84)', value: 'VV998918'},
  '85': {label: 'VV998914 - Pays de Loire (44, 49, 53, 72, 85)', value: 'VV998914'},
  '86': {label: 'VV998906 - Limousin Poitou Charentes (16, 17, 19, 23, 79, 86, 87)', value: 'VV998906'},
  '87': {label: 'VV998906 - Limousin Poitou Charentes (16, 17, 19, 23, 79, 86, 87)', value: 'VV998906'},
  '88': {label: 'VV998908 - Lorraine (54, 55, 57, 88)', value: 'VV998908'},
  '89': {label: 'VV998890 - Bourgogne / Franche Comté (21, 25, 39, 58, 70, 71, 89, 90)', value: 'VV998890'},
  '90': {label: 'VV998890 - Bourgogne / Franche Comté (21, 25, 39, 58, 70, 71, 89, 90)', value: 'VV998890'},
  '91': {label: 'VV998864 - Île-de-France (75, 77, 78, 91, 92, 93, 94, 95)', value: 'VV998864'},
  '92': {label: 'VV998864 - Île-de-France (75, 77, 78, 91, 92, 93, 94, 95)', value: 'VV998864'},
  '93': {label: 'VV998864 - Île-de-France (75, 77, 78, 91, 92, 93, 94, 95)', value: 'VV998864'},
  '94': {label: 'VV998864 - Île-de-France (75, 77, 78, 91, 92, 93, 94, 95)', value: 'VV998864'},
  '95': {label: 'VV998864 - Île-de-France (75, 77, 78, 91, 92, 93, 94, 95)', value: 'VV998864'},
  '971': {label: 'VV998926 - Guadeloupe (971)', value: 'VV998926'},
  '972': {label: 'VV998928 - Martinique (972)', value: 'VV998928'},
  '973': {label: 'VV998930 - Guyane (973)', value: 'VV998930'},
  '974': {label: 'VV998932 - Réunion (974)', value: 'VV998932'},
  '975': {label: 'VV998936 - Îles du Nord (975)', value: 'VV998936'},
  '976': {label: 'VV998934 - Mayotte (976)', value: 'VV998934'},
};

export interface IAction {
  value: string;
  label: string;
  ol_states: string[];
}

export const WOI_GENERIC_LINE_ACTIONS: IAction[] = [
  {
    value: 'CREATION',
    label: 'Création',
    ol_states: ['new']
  },
  {
    value: 'MODIF',
    label: 'Modification',
    ol_states: ['delivered']
  },
  {
    value: 'RESIL',
    label: 'Résiliation',
    ol_states: ['delivered', 'terminable']
  }
];

export const INVOICE_PAYMENT_STATUSES = {
  0: 'Payé',
  1: 'Non échu',
  2: 'Retard'
};

export const INVOICE_PAYMENT_STATUSES_ORDERED = [
  {key: '2', value: 'Retard'},
  {key: '1', value: 'Non échu'},
  {key: '0', value: 'Payé'}
];

export const PROJECT_ITEMS_STATUS_ORDERED_OPTIONS = [
  {key: 'new', value: 'Nouveau'},
  {key: 'in-progress', value: 'En Cours'},
  {key: 'done', value: 'Terminé'},
  {key: 'cancelled', value: 'Annulé'}
];

export const PROJECT_ITEMS_STATUS_OPTIONS = {
  'cancelled': 'Annulé',
  'done': 'Terminé',
  'in-progress': 'En Cours',
  'new': 'Nouveau'
};

export const WORK_ORDER_STATUS_ORDERED_OPTIONS = [
  {key: 'new', value: 'Nouveau'},
  {key: 'in-progress', value: 'En Cours'},
  {key: 'done', value: 'Terminé'},
  {key: 'cancelled', value: 'Annulé'},
  {key: 'error', value: 'Exception'}
];

export const WORK_ORDER_STATUS_OPTIONS = {
  'error': 'Exception',
  'cancelled': 'Annulé',
  'done': 'Terminé',
  'in-progress': 'En Cours',
  'new': 'Nouveau'
};

export const PROVI_PRODUCTS_LIST = ['P-PROVI', 'P-MANUL', 'P-MERAK', 'P-MAMER', 'P-OPROV', 'P-FLASH'];

export const OS_OPTIONS = {
  fortios: 'fortios',
  ios: 'ios',
  iosxr: 'iosxr',
  ips: 'ips',
  junos: 'junos',
  nxos: 'nxos',
  panos: 'panos',
  procurve: 'procurve',
  routeros: 'routeros',
  screenos: 'screenos'
};

export const MAPPING_TRANSITION_CONDITION_NOT_MET = {
  AT_LEAST_ONE_SDA_EXPECTED: `Il faut renseigner au moins un Sda pour continuer`,
  CANT_VALIDATE_THIS_QUOTE: `Une des versions de ce devis n'est pas dans le bon état`,
  INVALID_DUE_DATE: `La date prévu pour cette LR est dans le passé`,
  LRI_DOES_NOT_EXIST: `Il faut au moins un LRI pour démarrer la LR`,
  ND_WITHOUT_EQUIPMENT_PROVIDED: `Il y a au moins un ND sans équipemenent associé`,
  NEED_DEFAULT_CONTACT_IF_NONE_PROVIDED: `Il est nécessaire que le site posséde un contact si aucun n'est indiqué sur la LR`,
  NO_CONTACT_PROVIDED: `Il faut indiquer un contact`,
  NO_DELIVERY_ADDRESS: `Il faut indiquer une adresse de livraison`,
  NO_DELIVERY_SITE: `Il faut indiquer un site de livraison`,
  NO_DUE_DATE: `Il faut indiquer une date souhaitée`,
  QUANTITY_GAP: `Il y a un écart entre la quantité issue du devis et le nombre de sdas dans cette tâche`,
  NO_CUSTOMER_EMAILS: `Il faut renseigner au moins une adresse email pour démarrer l'intervention`,
  NO_EQUIPMENT_PROVIDED: `Il faut au moins fournir un équipment`,
  MISSING_SCANNED_EQUIPMENT: `Le nombre d'équipements scannés est insuffisant`,
  ERROR_IN_SCANNED_EQUIPMENT: `Il y a un équipement en erreur`,
  NO_FIAB_WANTED_PROVIDED: `Il faut renseigner une valeur pour le champ Demande de fiab pour continuer`,
  NO_TRACEABILITY_PROVIDED: `Il faut renseigner une valeur pour le champ Service`,
  NO_TRACEABILITY_NUMBER_PROVIDED: `Il faut renseigner une valeur pour le champ N⁰ de service`,
  NO_WITH_CALL_PROVIDED: `Il faut renseigner une valeur pour le champ Avec appel pour continuer`,
  SHIPPING_SITE_WITHOUT_LOCATION: `L'adresse de livraison n'est pas renseignée`,
  STATE_AVAILABLE_WITH_PORTA_REFUSED: `Un numéro issue d'une portabilité ne peut pas être rendu disponible`,
  STATE_PLANNED_WITH_CREATION_REFUSED: `Un numéro issue d'une création ne peut pas être planifié`,
  NO_VRF_CONFIGURED: 'Aucune vrf lan n\'est configuré',
};

export const ZONES_OPTIONS = [
  'W 1-1-1', 'W 1-1-2', 'W 1-1-3', 'W 1-1-4', 'W 1-1-5', 'W 1-1-6', 'W 1-1-7',
  'W 1-2-1', 'W 1-2-2', 'W 1-2-3', 'W 1-2-4', 'W 1-2-5', 'W 1-2-6', 'W 1-2-7',
  'W 1-3-1', 'W 1-3-2', 'W 1-3-3', 'W 1-3-4', 'W 1-3-5', 'W 1-3-6', 'W 1-3-7',
  'W 2-1-1', 'W 2-1-2', 'W 2-1-3', 'W 2-1-4', 'W 2-1-5', 'W 2-1-6', 'W 2-1-7',
  'W 2-2-1', 'W 2-2-2', 'W 2-2-3', 'W 2-2-4', 'W 2-2-5', 'W 2-2-6', 'W 2-2-7',
  'W 2-3-1', 'W 2-3-2', 'W 2-3-3', 'W 2-3-4', 'W 2-3-5', 'W 2-3-6', 'W 2-3-7',
  'W 3-1-1', 'W 3-1-2', 'W 3-1-3', 'W 3-1-4', 'W 3-1-5', 'W 3-1-6', 'W 3-1-7',
  'W 3-2-1', 'W 3-2-2', 'W 3-2-3', 'W 3-2-4', 'W 3-2-5', 'W 3-2-6', 'W 3-2-7',
  'W 3-3-1', 'W 3-3-2', 'W 3-3-3', 'W 3-3-4', 'W 3-3-5', 'W 3-3-6', 'W 3-3-7',
  'W 4-1-1', 'W 4-1-2', 'W 4-1-3', 'W 4-1-4', 'W 4-1-5', 'W 4-1-6', 'W 4-1-7',
  'W 4-2-1', 'W 4-2-2', 'W 4-2-3', 'W 4-2-4', 'W 4-2-5', 'W 4-2-6', 'W 4-2-7',
  'W 4-3-1', 'W 4-3-2', 'W 4-3-3', 'W 4-3-4', 'W 4-3-5', 'W 4-3-6', 'W 4-3-7',
  'W 5-1-1', 'W 5-1-2', 'W 5-1-3', 'W 5-1-4', 'W 5-1-5', 'W 5-1-6', 'W 5-1-7',
  'W 5-2-1', 'W 5-2-2', 'W 5-2-3', 'W 5-2-4', 'W 5-2-5', 'W 5-2-6', 'W 5-2-7',
  'W 5-3-1', 'W 5-3-2', 'W 5-3-3', 'W 5-3-4', 'W 5-3-5', 'W 5-3-6', 'W 5-3-7',
  'W 6-1-1', 'W 6-1-2', 'W 6-1-3', 'W 6-1-4', 'W 6-1-5', 'W 6-1-6', 'W 6-1-7',
  'W 6-2-1', 'W 6-2-2', 'W 6-2-3', 'W 6-2-4', 'W 6-2-5', 'W 6-2-6', 'W 6-2-7',
  'W 6-3-1', 'W 6-3-2', 'W 6-3-3', 'W 6-3-4', 'W 6-3-5', 'W 6-3-6', 'W 6-3-7',
  'W 7-1-1', 'W 7-1-2', 'W 7-1-3', 'W 7-1-4', 'W 7-1-5', 'W 7-1-6', 'W 7-1-7',
  'W 7-2-1', 'W 7-2-2', 'W 7-2-3', 'W 7-2-4', 'W 7-2-5', 'W 7-2-6', 'W 7-2-7',
  'W 7-3-1', 'W 7-3-2', 'W 7-3-3', 'W 7-3-4', 'W 7-3-5', 'W 7-3-6', 'W 7-3-7',
  'W 8-1-1', 'W 8-1-2', 'W 8-1-3', 'W 8-1-4', 'W 8-1-5', 'W 8-1-6', 'W 8-1-7',
  'W 8-2-1', 'W 8-2-2', 'W 8-2-3', 'W 8-2-4', 'W 8-2-5', 'W 8-2-6', 'W 8-2-7'];

export const EMAIL_TEMPLATES_OPTIONS = {
  delivery: 'Notification Delivery',
  intervention: 'Intervention',
  recovery: 'Relance',
  customer_satisfaction_survey: 'Enquête de satisfaction client',
  customer_confirmation: 'Mail de confirmation',
};

export enum NetworkDeviceClusterMode {
  None = 'none',
  IndividualIp = 'individual_ip',
  SharedIp = 'shared_ip'
}

export const ND_FIREWALL_CLUSTER_OPTIONS: ISelectOption[] = [
  { value: NetworkDeviceClusterMode.None, label: 'Sans cluster' },
  { value: NetworkDeviceClusterMode.IndividualIp, label: 'Cluster avec IP par équipement' },
  { value: NetworkDeviceClusterMode.SharedIp, label: 'Cluster avec IP partagé' },
];

export const ND_MERAKI_MX_CLUSTER_OPTIONS: ISelectOption[] = [
  { value: NetworkDeviceClusterMode.None, label: 'Sans cluster' },
  { value: NetworkDeviceClusterMode.SharedIp, label: 'Cluster avec IP partagé' },
];

export const GLOBAL_VRF_ID = 122;
export const GLOBAL_VRF_NAME = 'GLOBAL';

export const WAN_IP_GROUP_PUBLIC_CODE = 'WG-RMMIV';

export const EQUIPMENT_LOCATION_WAREHOUSES = {
  adista: 'Adista',
  cyberprotect: 'Cyberprotect',
  unyc: 'unyc',
  divers: 'Divers',
};

export const UNIVERSE_OPTIONS: Record<string, string> = {
  MWP: 'Communication et Collaboration',
  MNG: 'Services Managés',
  RES: 'Connectivité et Réseau d\'entreprise',
  ITA: 'Cloud Entreprise et Services Infrastructure',
  CYB: 'Cybersécurité',
  AUT: 'Autres',
};
