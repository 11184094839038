import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '@core/services/user.service';
import { ConfigService } from '@core/config/config.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-topbar-menus',
  templateUrl: './topbar-menus.component.html',
  styles: []
})
export class TopbarMenusComponent implements OnInit, OnDestroy {
  public configurableMenuList: any[] = [];
  public personalMenuList: any[] = [];
  public username: string;
  public woiListBaseUrl = '#/work-order-items/list/?' +
                    'state__nin=cancelled&' +
                    'state__nin=error&' +
                    'state__nin=done&' +
                    'state__nin=done-manual&' +
                    'state__nin=done-ko&' +
                    'work_order__order__state__nin=new&' +
                    'work_order__order__state__nin=cancelled';
  // Adding filter for when getting to the LR list page from top menu
  public logisticsRequestListBaseUrl = '#/logistics-requests/list/?' +
    'state__nin=cancelled&' +
    'state__nin=done';

  private signalSubscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private apiShiva: ApiShivaService,
    private signalsService: SignalsService,
    public config: ConfigService,
    private toastr: ToastrService
  ) {
    this.username = this.userService.getInfo().username;
  }

  public ngOnInit(): void {
    this._registerConfigurableMenuSignal();
    this._fetchConfigurableMenus();

    this._registerPersonalMenuSignal();
    this._fetchPersonalMenus();
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  // shortcut for the html template for the hasPermissions function
  public hasPermissions(...permissions: string[]) {
    return this.userService.hasPermissions(...permissions);
  }

  // -------------------------------------
  // Contextual help from confluence
  // -------------------------------------
  public showHelp() {
    // strip the actual path from the eventual parameters (we keep the view type)
    let basePath = window.location.hash.slice(1).replace(/\?.*/gi, '');

    // clean pk after "detail"
    if (basePath.includes('detail')) {
      basePath = basePath.split('detail')[0] + 'detail';
    }

    const regexpMatch = window.document.title.match(/^Antoine : (.+?)( [-:]{1} .+)?$/);
    if (regexpMatch) {
      let cleanedDocTitle = regexpMatch[1];
      if (basePath.includes('list')) {
        cleanedDocTitle += ' - Liste';
      }

      let confluenceUrl = this.config.confluenceHelpUrl;
      // adding the page title & the path to the confluence url to point to a specific page
      confluenceUrl += cleanedDocTitle + ' - ' + basePath;
      window.open(confluenceUrl);
    } else {
      this.toastr.error(`Impossible de déterminer l'url de la page pour afficher l'aide.`);
    }
  }

  public getIsCurrentClass(...paths: string[]): string {
    const currentPath =  this.route.snapshot['_routerState'].url;
    const isCurrent = paths.some((path: string) => currentPath.startsWith(path));
    return isCurrent ? 'active' : '';
  }

  // Shortcut for the tool menu isCurrent call
  public getIsToolCurrentClass() {
    return this.getIsCurrentClass(
      '/provider/', '/firewall',
      '/sla', '/configurable-menus', '/sequences',
      '/companies', '/tags', '/eligibility',
      '/templates', '/template-emails'
    );
  }

  // Shortcut for the production menu isCurrent call
  public getIsProductionCurrentClass() {
    return this.getIsCurrentClass(
      '/work-order-items',
      '/work-orders',
      '/orders-mrp', '/traceabilities',
      '/projects',
      '/providers', '/offers',
      '/technologies'
    );
  }

  // Shortcut for the finance menu isCurrent call
  public getIsFinanceCurrentClass() {
    return this.getIsCurrentClass(
      '/alexia',
      '/orders/', '/invoices', '/binders',
      '/billing-uploads', '/control-files',
      '/sage-exports', '/invoice-send-events',
      '/accounting-equipments',
      '/provider-orders',
      '/provider-invoices',
      '/fiscal-positions',
      '/invoices', '/accounts',
      '/currencies', '/payment-terms',
      '/payment-methods', '/taxes'
    );
  }

  public getIsNetworkCurrentClass() {
    return this.getIsCurrentClass(
      '/lines', '/vrfs',
      '/collection-nodes', '/network-devices',
      '/config-templates', '/operator-lines',
      '/provi-histories'
    );
  }

  // Shortcut for the tool menu isCurrent call
  public getIsLogisticsClass() {
    return this.getIsCurrentClass(
      '/equipment', '/logistics-requests'
    );
  }

  // Shortcut for the tool menu this.need call
  public hasToolsPermission() {
    return this.hasPermissions(
      'Antoine:EligibilityCanDisplay', 'Firewall:User',
      'Provitool:User', 'Firewall:ReadOnly',
      'Wira:TagAdmin', 'Wira:MenuAdmin',
      'Wira:SequenceCanCreate', 'Antoine:ExportAdmin',
      'EvaBackend:TemplateAdmin', 'EvaBackend:CompanyCanCreate'
    );
  }

  // Shortcut for the accounting
  public hasAccountingEquipmentPermission() {
    return this.hasPermissions(
      'ShivaBackend:AccountingEquipmentAdmin',
      'ShivaBackend:AccountingEquipmentLogistic',
      'ShivaBackend:ProviderInvoiceAdmin',
      'ShivaBackend:ProviderOrderAdmin',
      'ShivaBackend:ProviderOrderLogistic'
    );
  }

  private _registerConfigurableMenuSignal() {
    const sub = this.signalsService.subscribe('fetch-configurable-menus', () => this._fetchConfigurableMenus());
    this.signalSubscriptions.push(sub);
  }

  private _fetchConfigurableMenus() {
    this.apiShiva.configurable_menus.list({limit: 50, is_shared: true})
      .then((res) => {
        this.configurableMenuList = res['results'];
      }, err => {
        console.error('Erreur lors de la récupération des menus configurables', err);
        this.toastr.error('Erreur lors de la récupération des menus');
      });
  }

  private _registerPersonalMenuSignal() {
    const sub = this.signalsService.subscribe('fetch-personal-menus', () => this._fetchPersonalMenus());
    this.signalSubscriptions.push(sub);
  }

  private _fetchPersonalMenus() {
    this.apiShiva.configurable_menus.list({limit: 50, is_shared: false})
      .then((res) => {
        this.personalMenuList = res['results'];
      }, err => {
        console.error('Erreur lors de la récupération des menus personnels', err);
        this.toastr.error('Erreur lors de la récupération des menus personnels');
      });
  }
}
