<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <ng-container *ngIf="hasPermissions('Provitool:OperatorLineAdmin')">
        <div *ngIf="mode === 'normal'">
          <button type="button" class="btn btn-default" (click)="changePw(detail)"
                  *ngIf="detail.code && detail.offer?.type === 'dia' && detail.connection_method === 'ppp'">
            Changer le mot de passe
          </button>
        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
        </div>
        <div *ngIf="mode === 'edition'">
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.code"
                  [disabled]="f.invalid || loading || invalidVlan">
            Créer
          </button>
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.code"
                  [disabled]="f.invalid || loading || invalidVlan">
            Enregistrer
          </button>
          <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.code">
            Annuler
          </button>
        </div>
      </ng-container>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm">
    <div class="form-group">
      <label class="col-sm-2 control-label">
        Site de livraison <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-entity-field class="wcm-highlight-req" name="entity" [required]="true"
          [disabled]="!(mode === 'edition' && !detail.has_network_devices && ((hasPermissions('Antoine:OperatorLineCanUpdateLocation') && detail.id) || !detail.id))"
          [(ngModel)]="detail.entity" (ngModelChange)="entityUpdated()">
        </app-entity-field>
      </div>

      <div *ngIf="detail.code">
        <label class="col-sm-2 control-label">
          Statut
        </label>
        <div class="col-sm-4">
          <app-state-field model="operator-line" [pk]="detail.id" [state]="detail.state"
                          [disabled]="mode !== 'normal' || loading || detail.processing === 'automatic'">
          </app-state-field>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Adresse
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{detail.delivery_address}}</p>
      </div>

      <label class="col-sm-2 control-label">
        Processing
      </label>
      <div class="col-sm-4">
        <select class="form-control" [(ngModel)]="detail.processing"
                name="processing" [disabled]="mode === 'normal'">
          <option value="automatic">Automatique</option>
          <option value="manual">Manuel</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Tags
      </label>
      <div class="col-sm-4">
        <app-tags-field [(ngModel)]="detail.tags" name="tags" [disabled]="mode === 'normal'">
        </app-tags-field>
      </div>
    </div>

    <hr />

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Date de livraison
      </label>
      <div class="col-sm-4">
        <app-date-field name="deliveredDate" [disabled]="mode.toString() === 'normal'"
                        [(ngModel)]="detail.delivered_date" [mediumSize]="true">
        </app-date-field>
      </div>

      <label class="col-sm-2 control-label">
        Date de résiliation
      </label>
      <div class="col-sm-4">
        <app-date-field name="termindatedDate" [disabled]="mode.toString() === 'normal'"
                        [(ngModel)]="detail.terminated_date" [mediumSize]="true">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Offre <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-offer-field class="wcm-highlight-req" name="offer" [required]="true"
                         [disabled]="mode === 'normal'" [(ngModel)]="detail.offer"
                         [filters]="{provider__id: detail.collection_node?.provider?.id, type: initialOfferType}"
                         (ngModelChange)="checkProvider('offer')">
        </app-offer-field>
      </div>

      <ng-container *ngIf="detail.offer?.has_collection_nodes">
        <label class="col-sm-2 control-label">
          Porte de collecte <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <app-collection-node-field class="wcm-highlight-req" name="collection_node" [required]="true"
                                     [disabled]="mode === 'normal'" [(ngModel)]="detail.collection_node"
                                     [filters]="{offers__id: detail.offer?.id}" [disabledColumns]="collectionNodes.disabledColumns"
                                     (ngModelChange)="checkProvider('collection_node')">
          </app-collection-node-field>
        </div>
      </ng-container> <!-- /ngIf offer has_collection_nodes -->
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Technologie
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{detail.offer?.technology?.name}}</p>
      </div>

      <label class="col-sm-2 control-label">
        SLA
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{(detail.offer && detail.offer.contractual_sla) ? detail.offer.contractual_sla + ' Heure(s)' : 0}}</p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Fournisseur
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{detail.offer?.provider.name}}</p>
      </div>

      <div *ngIf="detail.collection_node">
        <label class="col-sm-2 control-label">
          Type
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{detail.collection_node?.type}}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Service Fournisseur
      </label>
      <div class="col-sm-4">
        <app-provider-traceability-field name="provider_traceability" [(ngModel)]="detail.provider_traceability"
          [disabled]="mode === 'normal' || !hasPermissions('Antoine:ProviderTraceabilityCanChangeOL')"
          [filters]="providerTraceabilityField?.filters">
        </app-provider-traceability-field>
      </div>

      <label class="col-sm-2 control-label">
        Engagement (mois)
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">{{ detail.provider_traceability?.commitment }}</p>
      </div>
    </div>

    <div *ngIf="detail.offer?.type === 'dia'">
      <hr />

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Modèle
        </label>
        <div class="col-sm-4">
          <input class="form-control" name="device_model" [readonly]="mode === 'normal'" [(ngModel)]="detail.device_model" />
        </div>

        <label class="col-sm-2 control-label">
          Méthode DIA
        </label>
        <div class="col-sm-4">
          <select class="form-control" name="connection_method" [disabled]="mode === 'normal'" [(ngModel)]="detail.connection_method">
            <option value="ppp">PPP</option>
            <option value="ip_fixe">IP Fixe</option>
            <option value="dhcp">DHCP</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Port de connexion
        </label>
        <div class="col-sm-4">
          <input class="form-control" name="device_connection_port" [readonly]="mode === 'normal'" [(ngModel)]="detail.device_connection_port" />
        </div>

        <ng-container [ngSwitch]="detail.connection_method">
          <div *ngSwitchCase="'ppp'">
            <label class="col-sm-2 control-label">
              Login
            </label>
            <div class="col-sm-4">
              <input class="form-control" name="ppp_login" [readonly]="mode === 'normal'" [(ngModel)]="detail.ppp_login" />
            </div>
          </div>
          <div *ngSwitchCase="'ip_fixe'">
            <label class="col-sm-2 control-label">
              IP du routeur Waycom
            </label>
            <div class="col-sm-4">
              <input class="form-control" name="waycom_address" [readonly]="mode === 'normal'" [(ngModel)]="detail.waycom_address" />
            </div>
          </div>
        </ng-container>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Numéro de série
        </label>
        <div class="col-sm-4">
          <input class="form-control" name="device_serial_number" [readonly]="mode === 'normal'" [(ngModel)]="detail.device_serial_number" />
        </div>

        <ng-container [ngSwitch]="detail.connection_method">
          <div *ngSwitchCase="'ppp'">
            <label class="col-sm-2 control-label">
              Mot de passe
            </label>
            <div class="col-sm-4 flex" *ngIf="!detail.code">
              <input class="form-control" type="password" name="ppp_password" [(ngModel)]="detail.ppp_password" />
            </div>
            <div class="col-sm-4 flex" *ngIf="detail.code">
              <ng-container [ngSwitch]="pppPswdDisplayed">
                <p *ngSwitchCase="false" class="flex-left flex-v-center form-control-static"> ********** </p>
                <p *ngSwitchCase="true" class="flex-left flex-v-center form-control-static"> {{ detail.ppp_password }} </p>
              </ng-container>

              <ng-container [ngSwitch]="pppPswdDisplayed" *ngIf="hasPermissions('Provitool:OperatorLineCanDecryptPassword')">
                <button *ngSwitchCase="false" class="btn btn-default bnt-xs flex-right" title="Afficher le mdp" (click)="fetchPsw()">
                  <i class="fas fa-eye"></i>
                </button>

                <button *ngSwitchCase="true" class="btn btn-default bnt-xs flex-right" title="Masquer le mdp" (click)="pppPswdDisplayed = false">
                  <i class="fas fa-eye"></i>
                </button>
              </ng-container>
            </div>
          </div>

          <div *ngSwitchCase="'ip_fixe'">
            <label class="col-sm-2 control-label">
              Passerelle
            </label>
            <div class="col-sm-4">
              <input class="form-control" name="gateway_address" [readonly]="mode === 'normal'" [(ngModel)]="detail.gateway_address" />
            </div>
          </div>
        </ng-container>
      </div>

      <div class="form-group" *ngIf="detail.connection_method === 'ppp'">
        <div class="col-sm-6"></div>
        <label class="col-sm-2 control-label" *ngIf="detail.connection_method === 'ppp'">
          IP du routeur Waycom
        </label>
        <div class="col-sm-4" *ngIf="detail.connection_method === 'ppp'">
          <input class="form-control" name="waycom_address" [readonly]="mode === 'normal'" [(ngModel)]="detail.waycom_address" />
        </div>
      </div>

      <div class="form-group" *ngIf="detail.offer?.type === 'dia'">
        <div class="col-sm-6"></div>
        <label class="col-sm-2 control-label">VLAN</label>
        <div class="col-sm-4">
          <input class="form-control" [readonly]="mode === 'normal'" type="number"
                 name="vlan" [(ngModel)]="detail.vlan" pattern="^\d+$" />
          <div class="error-message" *ngIf="f.controls.vlan?.errors?.pattern">
            Le VLAN n'accepte que des entiers positifs.
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="form-group">
      <label class="col-sm-2 control-label">
        GTR <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <select class="form-control wcm-highlight-req" name="gtr" [disabled]="mode === 'normal'" [(ngModel)]="detail.gtr" [required]="true">
          <option value="ho">Heures ouvrées</option>
          <option value="24">24/7</option>
        </select>
      </div>

      <div *ngIf="detail.offer?.has_pairs">
        <label class="col-sm-2 control-label" >
          Paires <i class="required">*</i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" name="pairs" [disabled]="mode === 'normal'" [(ngModel)]="detail.pairs">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="4">4</option>
          </select>
        </div>
      </div>

      <div *ngIf="detail.offer?.technology?.name === 'FTTH'">
        <label class="col-sm-2 control-label" >
          Ref. PTO
        </label>
        <div class="col-sm-4">
          <input  class="form-control" type='text' placeholder="FI-1234-5678"
                  name="pto_ref" [(ngModel)]="detail.pto_ref"
                  [readonly]="mode === 'normal'" [pattern]=patternRefPTO />
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.offer?.has_optical_type">
      <label class="col-sm-2 control-label">
        Type d'optique  <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-input-list-field [(ngModel)]="detail.optical_type"
                              [required]="detail.offer?.has_optical_type"
                              *ngIf="existingOpticalType"
                              [disabled]="mode === 'normal'"
                              [list]="existingOpticalType"
                              class="wcm-highlight-req"
                              name="opticalType">
        </app-input-list-field>
      </div>
    </div>


    <div class="form-group">
      <label class="col-sm-2 control-label">
        Référence de commande
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [placeholder]="detail.offer?.example_order_ref"
                [ngClass]="{'wcm-highlight-error': f.controls['order_ref']?.errors?.pattern}"
                name="order_ref" [readonly]="mode === 'normal'"
                [(ngModel)]="detail.order_ref" [pattern]="detail.offer?.order_ref_regexp"/>
        <div class="error-message" *ngIf="f.controls['order_ref']?.errors?.pattern">
          Le format de la Référence de commande est incorrect.<br>Exemple de référence : {{detail.offer?.example_order_ref}}
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Prestation
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [placeholder]="detail.offer?.example_technical_ref"
                [ngClass]="{'wcm-highlight-error': f.controls['technical_ref']?.errors?.pattern}"
                name="technical_ref" [readonly]="mode === 'normal'"
                [(ngModel)]="detail.technical_ref" [pattern]="detail.offer?.technical_ref_regexp"/>
        <div class="error-message" *ngIf="f.controls['technical_ref']?.errors?.pattern">
          Le format de la Prestation est incorrect.<br>Exemple de référence : {{detail.offer?.example_technical_ref}}
        </div>
      </div>

      <label class="col-sm-2 control-label">
        Identification de facturation
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" [placeholder]="detail.offer?.example_external_ref"
                [ngClass]="{'wcm-highlight-error': f.controls['external_ref']?.errors?.pattern}"
                name="external_ref" [readonly]="mode === 'normal'"
                [(ngModel)]="detail.external_ref"  [pattern]="detail.offer?.external_ref_regexp"/>
        <div class="error-message" *ngIf="f.controls['external_ref']?.errors?.pattern">
          Le format de l'Identification de facturation est incorrect.<br>Exemple de référence : {{detail.offer?.example_external_ref}}
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="['4G', '4G/5G'].includes(detail.offer?.technology?.name) && detail.network_device">
      <label class="col-sm-2 control-label">
        SIM 1
      </label>
      <div class="col-sm-4">
        <app-equipment-field name="first_sim_card_equipment"
                             [(ngModel)]="detail.network_device.first_sim_card_equipment"
                             [disabled]="true"
                             [onlyShowSn]="true">
        </app-equipment-field>
      </div>

      <label class="col-sm-2 control-label">
        SIM 2
      </label>
      <div class="col-sm-4">
        <app-equipment-field name="second_sim_card_equipment"
                             [(ngModel)]="detail.network_device.second_sim_card_equipment"
                             [disabled]="true"
                             [onlyShowSn]="true">
        </app-equipment-field>
      </div>
    </div>

    <div class="form-group">
      <div *ngIf="detail.offer?.has_ndi">
        <label class="col-sm-2 control-label">
          NDI crée
        </label>
        <div class="col-sm-4">
          <app-phone-number-field name="ndi_number" [(ngModel)]="detail.ndi_number"
                                [regionCode]="countryForNdi"
                                [disabled]="mode === 'normal'">
          </app-phone-number-field>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div *ngIf="detail.remote_id && detail.offer.provider.code === 'ORANGE'">
        <label class="col-sm-2 control-label">
          Remote Id
        </label>
        <div class="col-sm-4">
          <p class="form-control-static"> {{ detail.remote_id }}</p>
        </div>
      </div>

      <div *ngIf="detail.icc && detail.offer.provider.code === 'ORANGE'">
        <label class="col-sm-2 control-label">
          ICC
        </label>
        <div class="col-sm-4">
          <p class="form-control-static"> {{ detail.icc }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <ng-container *ngIf="detail.offer?.has_collection_nodes">
        <label class="col-sm-2 control-label">
          VLAN
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="number" min="0" max="4096"
                 [(ngModel)]="detail.vlan" name="vlan" [readonly]="mode === 'normal'"
                 (ngModelChange)="checkVlanNumber(detail.vlan)" />
          <div class="error-message" *ngIf="invalidVlan && mode=== 'edition'">
            La valeur doit être comprise entre 0 et 4096
          </div>
        </div>
      </ng-container> <!-- /ngIf offer has_collection_nodes-->
    </div>

    <div class="form-group" *ngIf="detail.offer?.has_max_bps">
      <label class="col-sm-2 control-label">
        Débit max <i class="fas fa-long-arrow-alt-up"></i> <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-bandwidth-field class="wcm-highlight-req" [required]="detail.offer?.has_max_bps" name="max_bps_up"
                             [disabled]="mode === 'normal'" [(ngModel)]="detail.max_bps_up">
        </app-bandwidth-field>
      </div>

      <label class="col-sm-2 control-label">
        Débit max <i class="fas fa-long-arrow-alt-down"></i> <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-bandwidth-field class="wcm-highlight-req" [required]="detail.offer?.has_max_bps" name="max_bps_down"
                             [disabled]="mode === 'normal'" [(ngModel)]="detail.max_bps_down">
        </app-bandwidth-field>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.offer?.has_guaranteed_bps">
      <label class="col-sm-2 control-label">
        Débit garanti <i class="fas fa-long-arrow-alt-up"></i> <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-bandwidth-field class="wcm-highlight-req" [required]="detail.offer?.has_guaranteed_bps" name="max_bps_up_guaranteed"
                             [disabled]="mode === 'normal'" [(ngModel)]="detail.max_bps_up_guaranteed">
        </app-bandwidth-field>
      </div>

      <label class="col-sm-2 control-label">
        Débit garanti <i class="fas fa-long-arrow-alt-down"></i> <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-bandwidth-field class="wcm-highlight-req" [required]="detail.offer?.has_guaranteed_bps" name="max_bps_down_guaranteed"
                             [disabled]="mode === 'normal'" [(ngModel)]="detail.max_bps_down_guaranteed">
        </app-bandwidth-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-10">
        <textarea class="form-control vertical-resize" name="description" rows="3"
                  [disabled]="mode === 'normal'" [(ngModel)]="detail.description">
        </textarea>
      </div>
    </div>

    <h3 class="page-header wcm-collapsible-header" *ngIf="detail.offer?.type === 'l2_ptp'">
      <span (click)="ptpCollapsed = !ptpCollapsed">
        <i class="fa fa-chevron-right chevron-rotate rotate-animation" [ngClass]="{'rotate': !ptpCollapsed}"></i>
        Sites
      </span>
    </h3>

    <div [ngbCollapse]="ptpCollapsed" *ngIf="detail.offer?.type === 'l2_ptp'">
      <div class="row" style="padding: 24px;">
        <div class="col-sm-6 site-a">
          <div class="form-group">
            <label class="col-sm-4 control-label">
              Site A (favoriser site client)
            </label>
            <div class="col-sm-8">
              <app-entity-field class="wcm-highlight-req" name="siteAEntity" [required]="true"
                          [disabled]="true"
                          [(ngModel)]="detail.entity">
              </app-entity-field>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-4 control-label">
              Position
            </label>
            <div class="col-sm-8">
              <input class="form-control" name="siteAPosition"
                      [readonly]="mode === 'normal'" [(ngModel)]="detail.site_a_position" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-4 control-label">
              Type de connecteur
            </label>
            <div class="col-sm-8">
              <div *ngIf="mode !== 'normal'; else enabledSiteAConnectorTypeBlock">
                <select class="form-control" [(ngModel)]="detail.site_a_connector_type" name="siteAConnectorType">
                  <option></option>
                  <option *ngFor="let opt of connectorTypes | keyvalue" [value]="opt.key">{{opt.value}}</option>
                </select>
              </div>
              <ng-template #enabledSiteAConnectorTypeBlock>
                <p class="form-control-static">{{detail.site_a_connector_type}}</p>
              </ng-template>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-4 control-label" *ngIf="detail.site_b_backbone_equipment">
              Équipement backbone
            </label>
            <div class="col-sm-8" *ngIf="detail.site_b_backbone_equipment">
              <app-backbone-equipment-field [disabled]="mode === 'normal'"
                                            [filters]="{datacenter__code: detail.entity?.code}"
                                            (ngModelChange)="onSiteABackboneEquipmentUpdated()"
                                            name="siteABackboneEquipment"
                                            [(ngModel)]="detail.site_a_backbone_equipment">
              </app-backbone-equipment-field>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-4 control-label" *ngIf="detail.site_a_backbone_equipment">
              Port physique <i class="required">*</i>
            </label>
            <div class="col-sm-8" *ngIf="detail.site_a_backbone_equipment">
              <input class="form-control wcm-highlight-req"
                      name="siteAPhysicalPort" [required]="detail.site_a_backbone_equipment"
                      pattern="^[A-Za-z0-9/\.]+$"
                      [readonly]="mode === 'normal'"
                      [(ngModel)]="detail.site_a_physical_port" />

              <div class="error-message" *ngIf="f.controls.siteAPhysicalPort?.errors?.pattern">
                Format du port physique invalide. Seules les lettres minuscules, majuscules, les chiffres et les symboles tiret(-) et slash(/) sont acceptés.
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="col-sm-4 control-label">
              Site B (favoriser DC Waycom) <i class="required">*</i>
            </label>
            <div class="col-sm-8">
              <app-entity-field name="siteBEntity"
                          class="wcm-highlight-req"
                          [required]="true"
                          [disabled]="mode === 'normal'"
                          (ngModelChange)="onSiteBEntityUpdated()"
                          [(ngModel)]="detail.site_b_entity">
              </app-entity-field>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-4 control-label">
              Position
            </label>
            <div class="col-sm-8">
              <input class="form-control" name="siteBPosition"
                      [readonly]="mode === 'normal'" [(ngModel)]="detail.site_b_position" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-4 control-label">
              Type de connecteur
            </label>
            <div class="col-sm-8">
              <div *ngIf="mode !== 'normal'; else enabledSiteBConnectorTypeBlock">
                <select class="form-control" [(ngModel)]="detail.site_b_connector_type"
                        [disabled]="mode.toString() === 'normal'" name="siteBConnectorType">
                  <option></option>
                  <option *ngFor="let opt of connectorTypes | keyvalue" [value]="opt.key">{{opt.value}}</option>
                </select>
              </div>
              <ng-template #enabledSiteBConnectorTypeBlock>
                <p class="form-control-static">{{detail.site_b_connector_type}}</p>
              </ng-template>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-4 control-label">
              Équipement backbone
            </label>
            <div class="col-sm-8">
              <app-backbone-equipment-field [disabled]="mode.toString() === 'normal'"
                                            [filters]="{datacenter__code: detail.site_b_entity?.code}"
                                            (ngModelChange)="onSiteBBackboneEquipmentUpdated($event)"
                                            name="siteBBackboneEquipment"
                                            [(ngModel)]="detail.site_b_backbone_equipment">
              </app-backbone-equipment-field>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-4 control-label" *ngIf="detail.site_b_backbone_equipment">
              Port physique <i class="required">*</i>
            </label>
            <div class="col-sm-8" *ngIf="detail.site_b_backbone_equipment">
              <input class="form-control wcm-highlight-req"
                      name="siteBPhysicalPort" [required]="detail.site_b_backbone_equipment"
                      pattern="^[A-Za-z0-9/\.]+$"
                      [readonly]="mode === 'normal'"
                      [(ngModel)]="detail.site_b_physical_port"
                      (ngModelChange)="reminderShortName($event)" />
              <div class="error-message" *ngIf="shortNameMsg && mode !== 'normal'">
                Merci d'utiliser le nom court des interfaces.
              </div>
              <div class="error-message" *ngIf="f.controls.siteBPhysicalPort?.errors?.pattern">
                Format du port physique invalide. Seules les lettres minuscules, majuscules, les chiffres et les symboles tiret(-) et slash(/) sont acceptés.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="detail.code">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
      <li [ngbNavItem]="0">
        <a ngbNavLink>Tâches</a>
        <ng-template ngbNavContent>
          <app-work-order-items-list [filters]="workOrderItems.filters"
                                     [disableUrlUpdate]="true"
                                     [disabledColumns]="workOrderItems.disabledColumns"
                                     [disabledButtons]="workOrderItems.disabledButtons"
                                     *ngIf="tabsStatus[0]">
          </app-work-order-items-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1">
        <a ngbNavLink>Fichiers <span class="badge primary" *ngIf="attachmentsCount">{{attachmentsCount}}</span></a>
        <ng-template ngbNavContent>
          <app-attachments-list [pk]="detail.id" model="operator-lines" [attachmentsApi]="attachmentsApi"
            (counter)="setAttachmentsCount($event)"></app-attachments-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Historique de workflow</a>
        <ng-template ngbNavContent>
          <app-workflow-histories-list [id]="detail.id" model="operator-line" *ngIf="tabsStatus[2]">
          </app-workflow-histories-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list model="operatorline" [id]="detail.id" *ngIf="tabsStatus[3]"></app-model-histories-list>
        </ng-template>
      </li>
    </ul>
    <!-- The tabs content is dislayed inside the outlet -->
    <div [ngbNavOutlet]="nav"></div>
  </ng-container> <!-- /tabs container -->
</div>
