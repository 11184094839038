import { Component, OnInit, Input, Injector, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { ConfigService } from '@core/config/config.service';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { IGenericApi, ITinyEntity } from '@core/interfaces';
import { BulkyExportWarningService } from '@app/core/components/bulky-export-warning/bulky-export-warning';
import { SignalsService } from '@core/services/signals.service';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';


@Component({
  selector: 'app-entities-list',
  templateUrl: './entities-list.component.html',
  styles: []
})
export class EntitiesListComponent extends GenericListComponent implements OnInit {

  // This ViewChild is used to expose the wcmTable controller from this controller.
  // It is used in the contact entity selection modal.
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  @Input() public disabledEntityView = false;
  @Input() public contactSelectionEntityView = false;
  @Input() public merakiParentLoading = false;
  @Output() public selectItems = new EventEmitter();
  @Output() public removeItem = new EventEmitter();
  @Output() public createParent = new EventEmitter();
  @Output() public removeParent = new EventEmitter();

  public permissionToUseForCreation: 'EvaBackend:EntityCanCreate' | 'EvaBackend:EntityCanCreateParent';
  public salesforceBaseUrl: string;
  private readonly disabledEntitiesApi: any;
  // The options are based on the is_open value, but we display 'is closed'
  // so we negate them
  public isClosedOptions = {true: 'Non', false: 'Oui'};
  public disableTaskExport = false;
  public api: IGenericApi;

  constructor(
    public injector: Injector,
    private signalsService: SignalsService,
    private bulkyExportWarningService: BulkyExportWarningService,
    private config: ConfigService,
    public ngbModal: NgbModal,
  ) {
    super(injector);
    this.salesforceBaseUrl = this.config.salesforceBaseUrl;
    this.disabledEntitiesApi = {
      list: (filters) => this.apiShiva.entities.disabled_list(filters)
    };
    this.localDisabledColumns = {
      is_open: true,
      is_invoiced: true,
      type__invoiceable: true,
      close_date: true,
      parent__code: true,
      selection: true,
      action: true,
      account_owner: true,
      manager_roc: true,
      manager_recovery: true,
      affiliate_ref: true
    };

    this.localDisabledButtons = {
      select: true,
      createParent: true
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.api = this.disabledEntityView ? this.disabledEntitiesApi : this.apiShiva.entities;
    const isEntitiesList = this.location.isCurrentPathEqualTo('/entities/list/');
    this.permissionToUseForCreation = isEntitiesList ? 'EvaBackend:EntityCanCreateParent' : 'EvaBackend:EntityCanCreate';
  }

  public filterProspects(wcmTable) {
    // Using filters method and extend to stack filters on entity list
    wcmTable.filters = { ...wcmTable.filters, is_prospect: 'true' };
    delete wcmTable.filters.is_customer;
    delete wcmTable.filters.parent__isnull;

    // trigger a table refresh
    wcmTable.refreshTable();
  }

  public filterCustomers(wcmTable) {
    // Using filters method and extend to stack filters on entity list

    wcmTable.filters = { ...wcmTable.filters, is_customer: 'true' };
    delete wcmTable.filters.is_prospect;
    delete wcmTable.filters.parent__isnull;

    // trigger a table refresh
    wcmTable.refreshTable();
  }

  public filterSites(wcmTable) {
    // Using filters method and extend to stack filters on entity list

    wcmTable.filters = { ...wcmTable.filters, parent__isnull: 'false' };
    delete wcmTable.filters.is_prospect;
    delete wcmTable.filters.is_customer;

    // Trigger a table refresh
    wcmTable.refreshTable();
  }

  public filterAll(wcmTable) {
    // Apply none of the previous filters in order to display all the entities

    delete wcmTable.filters.is_prospect;
    delete wcmTable.filters.is_customer;
    delete wcmTable.filters.parent__isnull;

    // Trigger a table refresh
    wcmTable.refreshTable();
  }


  public get canDisplayOpeningHoursImportButton(): boolean {
    return !this.localDisabledButtons?.import && this.hasPermissions('Antoine:OpeningHoursCanEdit');
  }

  public get canDisplayOpeningHoursExportButton(): boolean {
    return !this.localDisabledButtons?.export && this.hasPermissions('Antoine:ExportAdmin');
  }

  public get canDisplayOpeningHoursButton(): boolean {
    return this.canDisplayOpeningHoursImportButton || this.canDisplayOpeningHoursExportButton;
  }

  public get canDisplayContactsImportButton(): boolean {
    return !this.localDisabledButtons?.import && this.hasPermissions('ShivaBackend:ContactCanEdit');
  }

  public get canDisplayContactsExportButton(): boolean {
    return !this.localDisabledButtons?.export && this.hasPermissions('Antoine:ExportAdmin');
  }

  public get canDisplayContactsButton(): boolean {
    return this.canDisplayContactsImportButton || this.canDisplayContactsExportButton;
  }

  // Exports
  public filteredExport(tableFilters, export_type): void {
    const promise = this.bulkyExportWarningService.warningExport(100, this.wcmTable.itemCount);
    promise
      .then(() => this._doExport(tableFilters, export_type))
      .catch(() => {});
  }

  public _doExport(tableFilters, export_type): void {
    const filters = omit(tableFilters, 'limit', 'offset', 'ordering', 'serializer');
    let taskName = 'Export des entités';
    let endpoint = this.apiShiva.entities.export;
    if (export_type === 'contacts') {
      taskName = 'Export des contacts sur site';
      endpoint = this.apiShiva.contact_entity_relationships.export_direct;
    }
    else {
      filters.is_active = !this.disabledEntityView;
    }
    this.disableTaskExport = true;
    endpoint(filters)
      .then((res: {job_id: number, job_name: string}) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'))
      .finally(() => this.disableTaskExport = false);
  }

  // Exports portal account
  public filteredExportPortalAccount(tableFilters, export_type): void {
    const promise = this.bulkyExportWarningService.warningExport(100, this.wcmTable.itemCount);
      promise
        .then(() => this._doPortalExport(tableFilters, export_type))
        .catch(() => {});
  }

  public _doPortalExport(tableFilters, export_type): void {
    const filters = omit(tableFilters, 'limit', 'offset', 'ordering', 'serializer');
    const taskName = 'Export des comptes portails';
    this.disableTaskExport = true;
    this.apiShiva.entities.export_portal_account(filters)
      .then((res: {job_id: number, job_name: string}) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'))
      .finally(() => this.disableTaskExport = false);
  }

  // Exports opening hours
  public filteredExportOpeningHours(tableFilters): void {
    const filters = omit(tableFilters, 'limit', 'offset', 'ordering', 'serializer');
    const taskName = 'Export des heures d\'ouverture';
    this.disableTaskExport = true;
    this.apiShiva.entities.export_opening_hours(filters)
      .then((res: {job_id: number, job_name: string}) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      })
      .catch(() => this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.'))
      .finally(() => this.disableTaskExport = false);
  }

  // Imports
  public showImportContactsModal(): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    const endpoint = this.apiShiva.contact_entity_relationships.import_direct_relations;
    const jobName = 'Import des contacts sur site';
    modal.componentInstance.uploadUrl = endpoint();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = jobName;

    modal.result.then(
      () => this.wcmTable.refreshTable(),
      () => {}
    );
  }

  public showImportOpeningHoursModal(): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    const endpoint = this.apiShiva.entities.import_opening_hours;
    const jobName = 'Import des heures d\'ouverture';
    modal.componentInstance.uploadUrl =  endpoint();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = jobName;

    modal.result.then(
      () => this.wcmTable.refreshTable(),
      () => {}
    );
  }

  public select() {
    this.selectItems.emit();
  }

  public remove(item) {
    this.removeItem.emit(item);
  }

  public createMerakiParent() {
    this.createParent.emit();
  }

  public removeMerakiParent(wcmTable: WcmTableComponent) {
    const parentCodeList: string[] = Object.values(wcmTable.selectedItems).map((item: ITinyEntity) => item.code);
    this.removeParent.emit(parentCodeList);
  }

   // Templates
   public downloadTemplate(template_name): void {
    this.apiShiva.templates.detailByName(template_name)
      .then(res => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      });
  }

}
